import {
  createUser,
  deleteUser,
  getUserByUsername,
  getUsers,
  updateUser
} from '@/store/actions/user'
import { REDUCER_STATE } from '../../constants/store'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  [REDUCER_STATE.USER.FIELDS.IS_GETTING_USERS]: false,
  [REDUCER_STATE.USER.FIELDS.IS_CREATING_USER]: false,
  [REDUCER_STATE.USER.FIELDS.IS_GETTING_USER]: false,
  [REDUCER_STATE.USER.FIELDS.IS_UPDATING_USER]: false,
  [REDUCER_STATE.USER.FIELDS.IS_DELETING_USER]: false,
  [REDUCER_STATE.USER.FIELDS.USERS]: [],
  [REDUCER_STATE.USER.FIELDS.USER]: null,
  [REDUCER_STATE.USER.FIELDS.ERROR]: null
}
const reducers = {}
const extraReducers = {
  //get all users
  [getUsers.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.USER.FIELDS.ERROR]: null,
    [REDUCER_STATE.USER.FIELDS.IS_GETTING_USERS]: true,
    [REDUCER_STATE.USER.FIELDS.USERS]: []
  }),
  [getUsers.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.USER.FIELDS.IS_GETTING_USERS]: false,
    [REDUCER_STATE.USER.FIELDS.USERS]: action.payload
  }),
  [getUsers.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.USER.FIELDS.ERROR]: action.error,
    [REDUCER_STATE.USER.FIELDS.IS_GETTING_USERS]: false
  }),
  //get one user
  [getUserByUsername.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.USER.FIELDS.ERROR]: null,
    [REDUCER_STATE.USER.FIELDS.IS_GETTING_USER]: true,
    [REDUCER_STATE.USER.FIELDS.USER]: null
  }),
  [getUserByUsername.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.USER.FIELDS.IS_GETTING_USER]: false,
    [REDUCER_STATE.USER.FIELDS.USER]: action.payload.user
  }),
  [getUserByUsername.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.USER.FIELDS.ERROR]: action.error,
    [REDUCER_STATE.USER.FIELDS.IS_GETTING_USER]: false
  }),
  //create user
  [createUser.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.USER.FIELDS.ERROR]: null,
    [REDUCER_STATE.USER.FIELDS.IS_CREATING_USER]: true,
    [REDUCER_STATE.USER.FIELDS.USER]: null
  }),
  [createUser.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.USER.FIELDS.IS_CREATING_USER]: false,
    [REDUCER_STATE.USER.FIELDS.USER]: action.payload
  }),
  [createUser.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.USER.FIELDS.IS_CREATING_USER]: false,
    [REDUCER_STATE.USER.FIELDS.ERROR]: action.error
  }),
  //update user
  [updateUser.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.USER.FIELDS.ERROR]: null,
    [REDUCER_STATE.USER.FIELDS.IS_UPDATING_USER]: true,
    [REDUCER_STATE.USER.FIELDS.USER]: null
  }),
  [updateUser.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.USER.FIELDS.IS_UPDATING_USER]: false,
    [REDUCER_STATE.USER.FIELDS.USER]: action.payload
  }),
  [updateUser.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.USER.FIELDS.IS_UPDATING_USER]: false,
    [REDUCER_STATE.USER.FIELDS.ERROR]: action.error
  }),
  //delete user
  [deleteUser.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.USER.FIELDS.ERROR]: null,
    [REDUCER_STATE.USER.FIELDS.IS_DELETING_USER]: true,
    [REDUCER_STATE.USER.FIELDS.USER]: null
  }),
  [deleteUser.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.USER.FIELDS.IS_DELETING_USER]: false,
    [REDUCER_STATE.USER.FIELDS.USER]: action.payload
  }),
  [deleteUser.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.USER.FIELDS.IS_DELETING_USER]: false,
    [REDUCER_STATE.USER.FIELDS.ERROR]: action.error
  })
}
const slice = createSlice({
  name: REDUCER_STATE.USER.NAME,
  initialState,
  reducers,
  extraReducers
})

export default slice
