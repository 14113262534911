import BaseApi from './base'
import Store from '@/services/localStorage/base'
import { KEY_NAMES, STORE_NAMES } from '@/services/localStorage/constants'

/**
 * auth api class that wraps multiple auth api requests
 */
class authApi extends BaseApi {
  async register({ username, password }) {
    const _self = this
    try {
      const body = {
        username,
        password
      }
      const res = await _self.client.post(_self.endpoints.AUTH_REGISTER, body)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async login({ username, password }) {
    const _self = this
    try {
      const body = {
        username,
        password
      }
      const res = await _self.client.post(_self.endpoints.AUTH_LOGIN, body)
      const postProcessedRes = _self.postProcessor(res)

      const { user, accessToken, accessTokenExpire } = postProcessedRes

      const authLocalStore = new Store({ storeName: STORE_NAMES.AUTH })
      await authLocalStore.setItem(KEY_NAMES.ACCESS_TOKEN, accessToken)
      await authLocalStore.setItem(KEY_NAMES.ACCESS_TOKEN_EXPIRE, accessTokenExpire)

      return user
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async refreshToken() {
    const _self = this
    try {
      const res = await _self.client.post(_self.endpoints.AUTH_REFRESH_TOKEN)
      const postProcessedRes = _self.postProcessor(res)

      const { user, accessToken, accessTokenExpire } = postProcessedRes

      const authLocalStore = new Store({ storeName: STORE_NAMES.AUTH })
      await authLocalStore.setItem(KEY_NAMES.ACCESS_TOKEN, accessToken)
      await authLocalStore.setItem(KEY_NAMES.ACCESS_TOKEN_EXPIRE, accessTokenExpire)

      return user
    } catch (err) {
      await _self.errorHandler(err, false)
    }
  }

  async getUser() {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.AUTH_USER)
      const postProcessedRes = _self.postProcessor(res)

      return postProcessedRes.user
    } catch (err) {
      await _self.errorHandler(err)
    }
  }
}

export default authApi
