const NAME = 'edge'
const VERSION = 1

const STORE_NAMES = {
  AUTH: 'auth'
}

const KEY_NAMES = {
  // auth store
  ACCESS_TOKEN: 'access_token',
  ACCESS_TOKEN_EXPIRE: 'access_token_expire'
}

export { NAME, VERSION, KEY_NAMES, STORE_NAMES }
