import BaseApi from './base'
import notificationApi from '@/components/notification'
import { MSG } from '@/constants/messages'

/**
 * Family Cross api class that wraps multiple Family Cross api requests
 */
class FamilyCrossApi extends BaseApi {
  async getFamiliesCross() {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.FAMILIES_CROSS)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async getFamilyCrossById({ familyCrossId }) {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.FAMILY_CROSS({ familyCrossId }))
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async createFamilyCross(body) {
    const _self = this
    try {
      const res = await _self.client.post(_self.endpoints.FAMILIES_CROSS, body)
      notificationApi.success(MSG.FAMILY_CROSS_CREATED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async updateFamilyCross({ familyCrossId, ...rest }) {
    const _self = this
    try {
      const res = await _self.client.put(_self.endpoints.FAMILY_CROSS({ familyCrossId }), rest)
      notificationApi.success(MSG.FAMILY_CROSS_UPDATED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }
  async deleteFamilyCross({ familyCrossId }) {
    const _self = this
    try {
      const res = await _self.client.delete(_self.endpoints.FAMILY_CROSS({ familyCrossId }))
      notificationApi.success(MSG.FAMILY_CROSS_DELETED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }
}

export default FamilyCrossApi
