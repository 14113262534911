import notificationApi from '@/components/notification'
import ENDPOINTS from '@/services/api/endpoints'
import { authApi } from '@/services/api/index'
import Store from '@/services/localStorage/base'
import { KEY_NAMES, STORE_NAMES } from '@/services/localStorage/constants'
import { store as reduxStore } from '@/store'
import { setIsExpired } from '@/store/actions/auth'
import axios from 'axios'
import React from 'react'

const baseURL = process.env.REACT_APP_API_PATH || '/api'

const client = axios.create({
  baseURL,
  withCredentials: true
})

// auth request interceptor
client.interceptors.request.use(async (config) => {
  if (config.url === ENDPOINTS.AUTH_LOGIN || config.url === ENDPOINTS.AUTH_REFRESH_TOKEN) {
    return config
  }

  const authLocalStore = new Store({ storeName: STORE_NAMES.AUTH })
  let accessToken = await authLocalStore.getItem(KEY_NAMES.ACCESS_TOKEN)
  const accessTokenExpire = await authLocalStore.getItem(KEY_NAMES.ACCESS_TOKEN_EXPIRE)

  // Refresh the access token 5 seconds prior the real expiration
  // To avoid concurrency issues
  if (accessTokenExpire && Date.parse(accessTokenExpire) > Date.now() + 5000) {
    // access is not expired, attach Bearer token in header
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
  } else {
    // access token is expired, get a new one.
    await authApi.refreshToken()
    accessToken = await authLocalStore.getItem(KEY_NAMES.ACCESS_TOKEN)
    config.headers.Authorization = `Bearer ${accessToken}`
  }
  return config
})

// auth response interceptor
client.interceptors.response.use(null, async (error) => {
  if (error.response?.status === 401 && error.request?.responseURL?.endsWith('refresh-token')) {
    // Only show this error message once!
    if (reduxStore.getState()?.auth?.isExpired === false) {
      notificationApi.error(
        <div>
          The current login session is expired, you will need to <a href='#/logout'>login</a> again.
        </div>,
        'Unauthorized Request'
      )
    }
    await reduxStore.dispatch(setIsExpired())
  }
  throw error
})

export default client
