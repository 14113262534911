import { createAsyncThunk } from '@reduxjs/toolkit'
import { surveyApi } from '@/services/api'
import Survey_ACTION_TYPE from '@/store/types/survey'

const getSurveys = createAsyncThunk(Survey_ACTION_TYPE.GET_SURVEYS, async () => {
  const data = await surveyApi.getSurveys()
  return data.surveys
})

const getSurveyById = createAsyncThunk(Survey_ACTION_TYPE.GET_SURVEY, async (payload) => {
  return await surveyApi.getSurveyById(payload)
})

export { getSurveys, getSurveyById }
