import {
  getFamiliesCross,
  getFamilyCrossById,
  updateFamilyCross,
  createFamilyCross,
  deleteFamilyCross
} from '@/store/actions/familyCross'
import { REDUCER_STATE } from '../../constants/store'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  [REDUCER_STATE.FAMILY_CROSS.FIELDS.IS_GETTING_FAMILIES_CROSS]: false,
  [REDUCER_STATE.FAMILY_CROSS.FIELDS.IS_CREATING_FAMILY_CROSS]: false,
  [REDUCER_STATE.FAMILY_CROSS.FIELDS.IS_GETTING_FAMILY_CROSS]: false,
  [REDUCER_STATE.FAMILY_CROSS.FIELDS.IS_UPDATING_FAMILY_CROSS]: false,
  [REDUCER_STATE.FAMILY_CROSS.FIELDS.IS_DELETING_FAMILY_CROSS]: false,
  [REDUCER_STATE.FAMILY_CROSS.FIELDS.FAMILIES_CROSS]: [],
  [REDUCER_STATE.FAMILY_CROSS.FIELDS.FAMILY_CROSS]: null,
  [REDUCER_STATE.FAMILY_CROSS.FIELDS.ERROR]: null
}
const reducers = {}
const extraReducers = {
  //get all FAMILIES_CROSS
  [getFamiliesCross.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.ERROR]: null,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.IS_GETTING_FAMILIES_CROSS]: true,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.FAMILIES_CROSS]: []
  }),
  [getFamiliesCross.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.IS_GETTING_FAMILIES_CROSS]: false,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.FAMILIES_CROSS]: action.payload
  }),
  [getFamiliesCross.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.ERROR]: action.error,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.IS_GETTING_FAMILIES_CROSS]: false
  }),
  //get one FAMILY_CROSS
  [getFamilyCrossById.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.ERROR]: null,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.IS_GETTING_FAMILY_CROSS]: true,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.FAMILY_CROSS]: null
  }),
  [getFamilyCrossById.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.IS_GETTING_FAMILY_CROSS]: false,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.FAMILY_CROSS]: action.payload
  }),
  [getFamilyCrossById.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.ERROR]: action.error,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.IS_GETTING_FAMILY_CROSS]: false
  }),
  //create FAMILY_CROSS
  [createFamilyCross.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.ERROR]: null,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.IS_CREATING_FAMILY_CROSS]: true,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.FAMILY_CROSS]: null
  }),
  [createFamilyCross.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.IS_CREATING_FAMILY_CROSS]: false,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.FAMILY_CROSS]: action.payload
  }),
  [createFamilyCross.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.IS_CREATING_FAMILY_CROSS]: false,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.ERROR]: action.error
  }),
  //update FAMILY_CROSS
  [updateFamilyCross.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.ERROR]: null,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.IS_UPDATING_FAMILY_CROSS]: true,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.FAMILY_CROSS]: null
  }),
  [updateFamilyCross.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.IS_UPDATING_FAMILY_CROSS]: false,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.FAMILY_CROSS]: action.payload
  }),
  [updateFamilyCross.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.IS_UPDATING_FAMILY_CROSS]: false,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.ERROR]: action.error
  }),
  //delete FAMILY_CROSS
  [deleteFamilyCross.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.ERROR]: null,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.IS_DELETING_FAMILY_CROSS]: true,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.FAMILY_CROSS]: null
  }),
  [deleteFamilyCross.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.IS_DELETING_FAMILY_CROSS]: false,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.FAMILY_CROSS]: action.payload
  }),
  [deleteFamilyCross.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.IS_DELETING_FAMILY_CROSS]: false,
    [REDUCER_STATE.FAMILY_CROSS.FIELDS.ERROR]: action.error
  })
}
const slice = createSlice({
  name: REDUCER_STATE.FAMILY_CROSS.NAME,
  initialState,
  reducers,
  extraReducers
})

export default slice
