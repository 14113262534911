import { createAsyncThunk } from '@reduxjs/toolkit'
import { settingApi } from '@/services/api'
import SETTING_ACTION_TYPE from '@/store/types/setting'

const getSettings = createAsyncThunk(SETTING_ACTION_TYPE.GET_SETTINGS, async () => {
  const data = await settingApi.getSettings()
  return data.settings
})

const getSettingByType = createAsyncThunk(SETTING_ACTION_TYPE.GET_SETTING, async (payload) => {
  return await settingApi.getSettingByType(payload)
})

const updateSetting = createAsyncThunk(SETTING_ACTION_TYPE.UPDATE_SETTING, async (payload) => {
  return await settingApi.updateSetting(payload)
})

export { getSettings, getSettingByType, updateSetting }
