import { ttl } from '@/libs/utils'

const ENDPOINTS = {
  AUTH_LOGIN: '/login',
  AUTH_REGISTER: '/register',
  AUTH_USER: '/user',
  AUTH_REFRESH_TOKEN: '/refresh-token',

  USERS: '/users',
  USER: ttl`/users/${'username'}`,

  SETTINGS: '/settings',
  SETTING: ttl`/settings/${'type'}`,

  SURVEYS: '/qualtrics/surveys',
  SURVEY: ttl`/qualtrics/surveys${'surveyId'}`,

  CURRENT_CONTACT_LIST: '/qualtrics/recipients/list',
  UPDATE_CONTACTS: '/qualtrics/recipients/update',

  SERVICES: '/services',
  SERVICE: ttl`/services/${'serviceId'}`,

  INITIALIZE_SERVICES: '/import/initialize-services',
  ENROL_DIRECTORS: '/import/enrol/directors',
  ENROL_YEAR_3_TEACHERS: '/import/enrol/year3Teachers',
  ENROL_YEAR_4_TEACHERS: '/import/enrol/year4Teachers',
  ENROL_FAMILIES_CROSS: '/import/enrol/crossSectionalStudyParents',
  ENROL_FAMILIES_OBSERV: '/import/enrol/observationalStudyParents',

  YEAR_3_TEACHERS: '/year3Teachers',
  YEAR_3_TEACHER: ttl`/year3Teachers/${'teacherId'}`,
  YEAR_4_TEACHERS: '/year4Teachers',
  YEAR_4_TEACHER: ttl`/year4Teachers/${'teacherId'}`,

  /**
   * GET /api/export/archive -- List all export history
   * GET /api/export/archive/{archiveId}/download -- Download a specific historical export file
   * POST /api/export/report -- Export report of a type
   */
  EXPORT_ARCHIVES: '/export/archive',
  EXPORT_ARCHIVE: ttl`/export/archive/${'archiveId'}`,
  DOWNLOAD_ARCHIVE: ttl`/export/archive/${'archiveId'}/download`,
  EXPORT_REPORT: 'export/report',
  EXPORT_PARTICIPANTS: ttl`export/participants/${'participantType'}`,

  DIRECTORS: '/directors',
  DIRECTOR: ttl`/directors/${'directorId'}`,

  FAMILIES_CROSS: '/familyCrossSectionals',
  FAMILY_CROSS: ttl`/familyCrossSectionals/${'familyCrossId'}`,

  FAMILIES_OBSERV: '/familyObservationals',
  FAMILY_OBSERV: ttl`/familyObservationals/${'familyObservId'}`,

  UNIQUE_TEACHER_PARTICIPANTS: '/uniqueTeacherParticipants',
  UNIQUE_TEACHER_PARTICIPANT: ttl`/uniqueTeacherParticipants/${'uniqueTeacherParticipantId'}`,
  REFRESH_UNIQUE_TEACHER_PARTICIPANTS: '/uniqueTeacherParticipants/refresh-job'
}

export default ENDPOINTS
