import { createAsyncThunk } from '@reduxjs/toolkit'
import { authApi } from '@/services/api'
import {
  GET_USER,
  LOGIN,
  LOGOUT,
  REFRESH_TOKEN,
  REGISTER,
  SET_IS_EXPIRED
} from '@/store/types/auth'
import localforage from 'localforage'

const getUser = createAsyncThunk(GET_USER, async () => {
  return await authApi.getUser()
})

const login = createAsyncThunk(LOGIN, async (payload) => {
  return await authApi.login(payload)
})

const register = createAsyncThunk(REGISTER, async (payload) => {
  return await authApi.register(payload)
})

const refreshToken = createAsyncThunk(REFRESH_TOKEN, async () => {
  return await authApi.refreshToken()
})

const logout = createAsyncThunk(LOGOUT, async () => {
  return await localforage.clear()
})

/**
 * Set the `isExpired` flag in the store.auth.
 * Not using refreshToken() here so the user won't be kicked back to the login screen.
 * This flag is for banner display purpose only.
 */
const setIsExpired = (isExpired = true) => {
  return { type: SET_IS_EXPIRED, isExpired }
}

export { getUser, login, register, refreshToken, logout, setIsExpired }
