import { createAsyncThunk } from '@reduxjs/toolkit'
import { familyCrossApi } from '@/services/api'
import FAMILY_CROSS_ACTION_TYPES from '@/store/types/familyCross'

const getFamiliesCross = createAsyncThunk(
  FAMILY_CROSS_ACTION_TYPES.GET_FAMILIES_CROSS,
  async () => {
    const data = await familyCrossApi.getFamiliesCross()
    return data.familyCrossSectionals
  }
)

const getFamilyCrossById = createAsyncThunk(
  FAMILY_CROSS_ACTION_TYPES.GET_FAMILY_CROSS,
  async (payload) => {
    const data = await familyCrossApi.getFamilyCrossById(payload)
    return data.familyCrossSectional
  }
)

const updateFamilyCross = createAsyncThunk(
  FAMILY_CROSS_ACTION_TYPES.UPDATE_FAMILY_CROSS,
  async (payload) => {
    return await familyCrossApi.updateFamilyCross(payload)
  }
)

const createFamilyCross = createAsyncThunk(
  FAMILY_CROSS_ACTION_TYPES.CREATE_FAMILY_CROSS,
  async (payload) => {
    return await familyCrossApi.createFamilyCross(payload)
  }
)

const deleteFamilyCross = createAsyncThunk(
  FAMILY_CROSS_ACTION_TYPES.DELETE_FAMILY_CROSS,
  async (payload) => {
    return await familyCrossApi.deleteFamilyCross(payload)
  }
)
export {
  getFamiliesCross,
  getFamilyCrossById,
  updateFamilyCross,
  createFamilyCross,
  deleteFamilyCross
}
