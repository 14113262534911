import { notification } from 'antd'

const defaultConfig = {
  placement: 'bottomRight',
  duration: 3
}

/**
 * Notification api of antd
 */
const notificationApi = {
  error: (message, title) => {
    notification.error({
      ...defaultConfig,
      message: title || 'Error',
      description: message
    })
  },
  success: (message, title) => {
    notification.success({
      ...defaultConfig,
      message: title || 'Success',
      description: message
    })
  },
  info: (message, title) => {
    notification.info({
      ...defaultConfig,
      message: title || 'Information',
      description: message
    })
  },
  warning: (message, title) => {
    notification.warning({
      ...defaultConfig,
      message: title || 'Warning',
      description: message
    })
  }
}

export default notificationApi
