import BaseApi from './base'
import notificationApi from '@/components/notification'
import { MSG } from '@/constants/messages'

/**
 * setting api class that wraps multiple setting api requests
 */
class SettingApi extends BaseApi {
  async getSettings() {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.SETTINGS)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async getSettingByType({ type }) {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.SETTING({ type }))
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async updateSetting({ type, value }) {
    const _self = this
    try {
      const body = { type, value }
      const res = await _self.client.put(_self.endpoints.SETTING({ type }), body)
      notificationApi.success(MSG.CHANGE_SAVED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }
}

export default SettingApi
