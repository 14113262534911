import { createAsyncThunk } from '@reduxjs/toolkit'
import { exportApi } from '@/services/api'
import EXPORT_ACTION_TYPE from '@/store/types/export'

const getAllArchives = createAsyncThunk(EXPORT_ACTION_TYPE.GET_ALL_ARCHIVES, async () => {
  const data = await exportApi.getAllExportArchives()
  return data.archives
})

const getOneArchive = createAsyncThunk(EXPORT_ACTION_TYPE.GET_ONE_ARCHIVE, async (payload) => {
  return await exportApi.getOneExportArchive(payload)
})

const downloadOneArchive = createAsyncThunk(
  EXPORT_ACTION_TYPE.DOWNLOAD_ONE_ARCHIVE,
  async (payload) => {
    const data = await exportApi.downloadOneArchive(payload)
    return URL.createObjectURL(data)
  }
)

const exportReport = createAsyncThunk(EXPORT_ACTION_TYPE.EXPORT_REPORT, async (payload) => {
  const data = await exportApi.exportReport(payload)
  return URL.createObjectURL(data)
})
export { getAllArchives, exportReport, getOneArchive, downloadOneArchive }
