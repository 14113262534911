import React from 'react'
import BaseApi from './base'
import notificationApi from '@/components/notification'
import { MSG } from '@/constants/messages'

/**
 * import api class that can initialise services
 */

class ImportApi extends BaseApi {
  async initializeServices(file) {
    const _self = this
    try {
      const res = await _self.client.post(_self.endpoints.INITIALIZE_SERVICES, file)
      notificationApi.success(MSG.FILE_UPLOADED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async enrolDirectors() {
    const _self = this
    try {
      const res = await _self.client.post(_self.endpoints.ENROL_DIRECTORS)
      if (res.data.data?.conflicts?.length) {
        notificationApi.warning(
          <div>
            {res.data.data.conflicts.map((conflict) => (
              <div key={conflict}>{conflict}</div>
            ))}
          </div>,
          MSG.DIRECTORS_PARTIALLY_ENROLLED
        )
      } else {
        notificationApi.success(MSG.DIRECTORS_ENROLLED)
      }
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async enrolYear3Teachers() {
    const _self = this
    try {
      const res = await _self.client.post(_self.endpoints.ENROL_YEAR_3_TEACHERS)
      if (res.data.data?.conflicts?.length) {
        notificationApi.warning(
          <div>
            {res.data.data.conflicts.map((conflict) => (
              <div key={conflict}>{conflict}</div>
            ))}
          </div>,
          MSG.YEAR_3_TEACHERS_PARTIALLY_ENROLLED
        )
      } else {
        notificationApi.success(MSG.YEAR_3_TEACHERS_ENROLLED)
      }
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async enrolYear4Teachers() {
    const _self = this
    try {
      const res = await _self.client.post(_self.endpoints.ENROL_YEAR_4_TEACHERS)
      if (res.data.data?.conflicts?.length) {
        notificationApi.warning(
          <div>
            {res.data.data.conflicts.map((conflict) => (
              <div key={conflict}>{conflict}</div>
            ))}
          </div>,
          MSG.YEAR_4_TEACHERS_PARTIALLY_ENROLLED
        )
      } else {
        notificationApi.success(MSG.YEAR_4_TEACHERS_ENROLLED)
      }
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async enrolFamiliesCross() {
    const _self = this
    try {
      const res = await _self.client.post(_self.endpoints.ENROL_FAMILIES_CROSS)
      if (res.data.data?.conflicts?.length) {
        notificationApi.warning(
          <div>
            {res.data.data.conflicts.map((conflict) => (
              <div key={conflict}>{conflict}</div>
            ))}
          </div>,
          MSG.FAMILIES_CROSS_PARTIALLY_ENROLLED
        )
      } else {
        notificationApi.success(MSG.FAMILIES_CROSS_ENROLLED)
      }
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async enrolFamiliesObserv() {
    const _self = this
    try {
      const res = await _self.client.post(_self.endpoints.ENROL_FAMILIES_OBSERV)
      if (res.data.data?.conflicts?.length) {
        notificationApi.warning(
          <div>
            {res.data.data.conflicts.map((conflict) => (
              <div key={conflict}>{conflict}</div>
            ))}
          </div>,
          MSG.FAMILIES_OBSERV_PARTIALLY_ENROLLED
        )
      } else {
        notificationApi.success(MSG.FAMILIES_OBSERV_ENROLLED)
      }
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }
}

export default ImportApi
