import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ROOT_ROUTES } from '@/constants/routes'
import { connect } from 'react-redux'
import { REDUCER_STATE } from '@/constants/store'

/**
 * Only un-authenticated user can access public routes
 * @param children children that needs to parsed to route
 * @param authUser authenticated user
 * @param rest other properties for route
 * @return {JSX.Element}
 * @constructor
 */
const PublicRoute = ({ children, authUser, ...rest }) => (
  <Route
    {...rest}
    render={({ location }) =>
      authUser ? (
        <Redirect to={{ pathname: ROOT_ROUTES.MENU_LAYOUT_VIEW, state: { from: location } }} />
      ) : (
        children
      )
    }
  />
)

PublicRoute.propTypes = {
  children: PropTypes.object.isRequired,
  authUser: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    authUser: state[REDUCER_STATE.AUTH.NAME][REDUCER_STATE.AUTH.FIELDS.DATA_AUTH_USER]
  }
}

export default connect(mapStateToProps)(PublicRoute)
