import BaseApi from './base'
import notificationApi from '@/components/notification'
import { MSG } from '@/constants/messages'

/**
 * user api class that wraps multiple user api requests
 */
class ServiceApi extends BaseApi {
  async getServices() {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.SERVICES)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async getServiceById({ serviceId }) {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.SERVICE({ serviceId }))
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async createService(body) {
    const _self = this
    try {
      const res = await _self.client.post(_self.endpoints.SERVICES, body)
      notificationApi.success(MSG.SERVICE_CREATED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async updateService({ serviceId, ...rest }) {
    const _self = this
    try {
      const res = await _self.client.put(_self.endpoints.SERVICE({ serviceId }), rest)
      notificationApi.success(MSG.SERVICE_UPDATED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }
}

export default ServiceApi
