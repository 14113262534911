const REDUCER_STATE = {
  ROUTER: {
    NAME: 'router'
  },
  AUTH: {
    NAME: 'auth',
    FIELDS: {
      // data
      DATA_AUTH_USER: 'authUser',
      DATA_ACCESS_TOKEN: 'accessToken',
      DATA_ACCESS_TOKEN_EXPIRE: 'accessTokenExpire',
      DATA_REFRESH_TOKEN: 'refreshToken',

      // pending
      IS_LOGGING_IN: 'isLoggingIn',
      IS_GETTING_USER: 'isGettingUser',
      IS_GETTING_REFRESH_TOKEN: 'isGettingRefreshToken',

      // error
      ERROR_LOGIN: 'errorLogin',
      ERROR_GET_USER: 'errorGetUser',
      ERROR_REFRESH_TOKEN: 'errorRefreshToken',

      // "isExpired" flag
      IS_EXPIRED: 'isExpired'
    }
  },
  USER: {
    NAME: 'user',
    FIELDS: {
      USERS: 'users',
      USER: 'user',
      IS_GETTING_USERS: 'isGettingUsers',
      IS_CREATING_USER: 'isCreatingUser',
      IS_GETTING_USER: 'isGettingUser',
      IS_UPDATING_USER: 'isUpDatingUser',
      IS_DELETING_USER: 'isDeletingUser',
      ERROR: 'error'
    }
  },
  SETTING: {
    NAME: 'setting',
    FIELDS: {
      SETTINGS: 'settings',
      SETTING: 'setting',
      IS_GETTING_SETTINGS: 'isGettingSettings',
      IS_GETTING_SETTING: 'isGettingSetting',
      IS_UPDATING_SETTING: 'isUpDatingSetting',
      ERROR: 'error'
    }
  },
  SURVEY: {
    NAME: 'survey',
    FIELDS: {
      SURVEYS: 'surveys',
      SURVEY: 'survey',
      IS_GETTING_SURVEYS: 'isGettingSurveys',
      IS_GETTING_SURVEY: 'isGettingSurvey',
      ERROR: 'error'
    }
  },
  SERVICE: {
    NAME: 'service',
    FIELDS: {
      SERVICES: 'services',
      SERVICE: 'service',
      IS_GETTING_SERVICES: 'isGettingServices',
      IS_CREATING_SERVICE: 'isCreatingService',
      IS_GETTING_SERVICE: 'isGettingService',
      IS_UPDATING_SERVICE: 'isUpDatingService',
      IS_DELETING_SERVICE: 'isDeletingService',
      ERROR: 'error'
    }
  },
  IMPORT: {
    NAME: 'import',
    FIELDS: {
      IS_INITIALIZING_SERVICES: 'isInitializingServices',
      IS_ENROLLING_DIRECTORS: 'isEnrollingDirectors',
      IS_ENROLLING_YEAR_3_TEACHERS: 'isEnrollingYear3Teachers',
      IS_ENROLLING_YEAR_4_TEACHERS: 'isEnrollingYear4Teachers',
      IS_ENROLLING_FAMILIES_CROSS: 'isEnrollingFamiliesCross',
      IS_ENROLLING_FAMILIES_OBSERV: 'isEnrollingFamiliesObserv',
      ERROR: 'error'
    }
  },
  FAMILY_CROSS: {
    NAME: 'familyCross',
    FIELDS: {
      FAMILIES_CROSS: 'familiesCross',
      FAMILY_CROSS: 'familyCross',
      IS_GETTING_FAMILIES_CROSS: 'isGettingFamiliesCross',
      IS_CREATING_FAMILY_CROSS: 'isCreatingFamilyCross',
      IS_GETTING_FAMILY_CROSS: 'isGettingFamilyCross',
      IS_UPDATING_FAMILY_CROSS: 'isUpDatingFamilyCross',
      IS_DELETING_FAMILY_CROSS: 'isDeletingFamilyCross',
      ERROR: 'error'
    }
  },
  FAMILY_OBSERV: {
    NAME: 'familyObserv',
    FIELDS: {
      FAMILIES_OBSERV: 'familiesObserv',
      FAMILY_OBSERV: 'familyObserv',
      IS_GETTING_FAMILIES_OBSERV: 'isGettingFamiliesObserv',
      IS_CREATING_FAMILY_OBSERV: 'isCreatingFamilyObserv',
      IS_GETTING_FAMILY_OBSERV: 'isGettingFamilyObserv',
      IS_UPDATING_FAMILY_OBSERV: 'isUpDatingFamilyObserv',
      IS_DELETING_FAMILY_OBSERV: 'isDeletingFamilyObserv',
      ERROR: 'error'
    }
  },
  YEAR_3_TEACHER: {
    NAME: 'year3Teacher',
    year34TeachersOfService: 'year3Teacher',
    FIELDS: {
      TEACHERS: 'year3Teachers',
      TEACHER: 'year3Teacher',
      IS_GETTING_TEACHERS: 'isGettingYear3Teachers',
      IS_CREATING: 'isCreatingYear3Teacher',
      IS_GETTING_TEACHER: 'isGettingYear3Teacher',
      IS_UPDATING: 'isUpDatingYear3Teacher',
      IS_DELETING: 'isDeletingYear3Teacher',
      ERROR: 'error'
    }
  },
  YEAR_4_TEACHER: {
    NAME: 'year4Teacher',
    FIELDS: {
      TEACHERS: 'year4Teachers',
      TEACHER: 'year4Teacher',
      IS_GETTING_TEACHERS: 'isGettingYear4Teachers',
      IS_CREATING: 'isCreatingYear4Teacher',
      IS_GETTING_TEACHER: 'isGettingYear4Teacher',
      IS_UPDATING: 'isUpDatingYear4Teacher',
      IS_DELETING: 'isDeletingYear4Teacher',
      ERROR: 'error'
    }
  },
  EXPORT: {
    NAME: 'export',
    FIELDS: {
      ARCHIVE: 'archive',
      ARCHIVES: 'archives',
      ARCHIVE_URL: 'archiveUrl',
      REPORT_URL: 'reportUrl',
      IS_GETTING_ARCHIVE: 'isGettingArchive',
      IS_GETTING_ARCHIVES: 'isGettingArchives',
      IS_DOWNLOADING_ARCHIVE: 'isDownloadingArchive',
      IS_EXPORTING_REPORT: 'isExportingReport',
      ARCHIVE_ERROR: 'archiveError',
      EXPORT_ERROR: 'exportError'
    }
  },
  DIRECTOR: {
    NAME: 'director',
    FIELDS: {
      DIRECTORS: 'directors',
      DIRECTOR: 'director',
      IS_GETTING_DIRECTORS: 'isGettingDirectors',
      IS_CREATING_DIRECTOR: 'isCreatingDirector',
      IS_GETTING_DIRECTOR: 'isGettingDirector',
      IS_UPDATING_DIRECTOR: 'isUpDatingDirector',
      IS_DELETING_DIRECTOR: 'isDeletingDirector',
      ERROR: 'error'
    }
  },
  UNIQUE_TEACHER_PARTICIPANT: {
    NAME: 'uniqueTeacherParticipant',
    FIELDS: {
      UNIQUE_TEACHER_PARTICIPANTS: 'uniqueTeacherParticipants',
      UNIQUE_TEACHER_PARTICIPANT: 'uniqueTeacherParticipant',
      IS_GETTING_UNIQUE_TEACHER_PARTICIPANTS: 'isGettingUniqueTeacherParticipants',
      IS_GETTING_UNIQUE_TEACHER_PARTICIPANT: 'isGettingUniqueTeacherParticipant',
      IS_UPDATING_UNIQUE_TEACHER_PARTICIPANT: 'isUpdatingUniqueTeacherParticipant',
      IS_DELETING_UNIQUE_TEACHER_PARTICIPANT: 'isDeletingUniqueTeacherParticipant',
      IS_REFRESHING_UNIQUE_TEACHER_PARTICIPANTS: 'isRefreshingUniqueTeacherParticipants',
      ERROR: 'error'
    }
  }
}

export { REDUCER_STATE }
