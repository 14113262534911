import { getSurveys, getSurveyById } from '@/store/actions/survey'
import { REDUCER_STATE } from '../../constants/store'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  [REDUCER_STATE.SURVEY.FIELDS.IS_GETTING_SURVEYS]: false,
  [REDUCER_STATE.SURVEY.FIELDS.IS_GETTING_SURVEY]: false,
  [REDUCER_STATE.SURVEY.FIELDS.SURVEYS]: [],
  [REDUCER_STATE.SURVEY.FIELDS.SURVEY]: null,
  [REDUCER_STATE.SURVEY.FIELDS.ERROR]: null
}
const reducers = {}
const extraReducers = {
  //get all SURVEYs
  [getSurveys.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.SURVEY.FIELDS.ERROR]: null,
    [REDUCER_STATE.SURVEY.FIELDS.IS_GETTING_SURVEYS]: true,
    [REDUCER_STATE.SURVEY.FIELDS.SURVEYS]: []
  }),
  [getSurveys.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.SURVEY.FIELDS.IS_GETTING_SURVEYS]: false,
    [REDUCER_STATE.SURVEY.FIELDS.SURVEYS]: action.payload
  }),
  [getSurveys.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.SURVEY.FIELDS.ERROR]: action.error,
    [REDUCER_STATE.SURVEY.FIELDS.IS_GETTING_SURVEYS]: false
  }),
  //get one SURVEY
  [getSurveyById.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.SURVEY.FIELDS.ERROR]: null,
    [REDUCER_STATE.SURVEY.FIELDS.IS_GETTING_SURVEY]: true,
    [REDUCER_STATE.SURVEY.FIELDS.SURVEY]: null
  }),
  [getSurveyById.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.SURVEY.FIELDS.IS_GETTING_SURVEY]: false,
    [REDUCER_STATE.SURVEY.FIELDS.SURVEY]: action.payload.SURVEY
  }),
  [getSurveyById.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.SURVEY.FIELDS.ERROR]: action.error,
    [REDUCER_STATE.SURVEY.FIELDS.IS_GETTING_SURVEY]: false
  })
}
const slice = createSlice({
  name: REDUCER_STATE.SURVEY.NAME,
  initialState,
  reducers,
  extraReducers
})

export default slice
