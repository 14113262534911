import {
  getFamiliesObserv,
  getFamilyObservById,
  updateFamilyObserv,
  createFamilyObserv,
  deleteFamilyObserv
} from '@/store/actions/familyObserv'
import { REDUCER_STATE } from '../../constants/store'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  [REDUCER_STATE.FAMILY_OBSERV.FIELDS.IS_GETTING_FAMILIES_OBSERV]: false,
  [REDUCER_STATE.FAMILY_OBSERV.FIELDS.IS_CREATING_FAMILY_OBSERV]: false,
  [REDUCER_STATE.FAMILY_OBSERV.FIELDS.IS_GETTING_FAMILY_OBSERV]: false,
  [REDUCER_STATE.FAMILY_OBSERV.FIELDS.IS_UPDATING_FAMILY_OBSERV]: false,
  [REDUCER_STATE.FAMILY_OBSERV.FIELDS.IS_DELETING_FAMILY_OBSERV]: false,
  [REDUCER_STATE.FAMILY_OBSERV.FIELDS.FAMILIES_OBSERV]: [],
  [REDUCER_STATE.FAMILY_OBSERV.FIELDS.FAMILY_OBSERV]: null,
  [REDUCER_STATE.FAMILY_OBSERV.FIELDS.ERROR]: null
}
const reducers = {}
const extraReducers = {
  //get all FAMILIES_OBSERV
  [getFamiliesObserv.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.ERROR]: null,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.IS_GETTING_FAMILIES_OBSERV]: true,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.FAMILIES_OBSERV]: []
  }),
  [getFamiliesObserv.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.IS_GETTING_FAMILIES_OBSERV]: false,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.FAMILIES_OBSERV]: action.payload
  }),
  [getFamiliesObserv.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.ERROR]: action.error,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.IS_GETTING_FAMILIES_OBSERV]: false
  }),
  //get one FAMILY_OBSERV
  [getFamilyObservById.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.ERROR]: null,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.IS_GETTING_FAMILY_OBSERV]: true,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.FAMILY_OBSERV]: null
  }),
  [getFamilyObservById.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.IS_GETTING_FAMILY_OBSERV]: false,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.FAMILY_OBSERV]: action.payload
  }),
  [getFamilyObservById.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.ERROR]: action.error,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.IS_GETTING_FAMILY_OBSERV]: false
  }),
  //create FAMILY_OBSERV
  [createFamilyObserv.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.ERROR]: null,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.IS_CREATING_FAMILY_OBSERV]: true,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.FAMILY_OBSERV]: null
  }),
  [createFamilyObserv.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.IS_CREATING_FAMILY_OBSERV]: false,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.FAMILY_OBSERV]: action.payload
  }),
  [createFamilyObserv.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.IS_CREATING_FAMILY_OBSERV]: false,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.ERROR]: action.error
  }),
  //update FAMILY_OBSERV
  [updateFamilyObserv.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.ERROR]: null,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.IS_UPDATING_FAMILY_OBSERV]: true,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.FAMILY_OBSERV]: null
  }),
  [updateFamilyObserv.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.IS_UPDATING_FAMILY_OBSERV]: false,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.FAMILY_OBSERV]: action.payload
  }),
  [updateFamilyObserv.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.IS_UPDATING_FAMILY_OBSERV]: false,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.ERROR]: action.error
  }),
  //delete FAMILY_OBSERV
  [deleteFamilyObserv.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.ERROR]: null,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.IS_DELETING_FAMILY_OBSERV]: true,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.FAMILY_OBSERV]: null
  }),
  [deleteFamilyObserv.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.IS_DELETING_FAMILY_OBSERV]: false,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.FAMILY_OBSERV]: action.payload
  }),
  [deleteFamilyObserv.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.IS_DELETING_FAMILY_OBSERV]: false,
    [REDUCER_STATE.FAMILY_OBSERV.FIELDS.ERROR]: action.error
  })
}
const slice = createSlice({
  name: REDUCER_STATE.FAMILY_OBSERV.NAME,
  initialState,
  reducers,
  extraReducers
})

export default slice
