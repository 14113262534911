import { createService, getServiceById, getServices, updateService } from '@/store/actions/service'
import { REDUCER_STATE } from '../../constants/store'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  [REDUCER_STATE.SERVICE.FIELDS.IS_GETTING_SERVICES]: false,
  [REDUCER_STATE.SERVICE.FIELDS.IS_CREATING_SERVICE]: false,
  [REDUCER_STATE.SERVICE.FIELDS.IS_GETTING_SERVICE]: false,
  [REDUCER_STATE.SERVICE.FIELDS.IS_UPDATING_SERVICE]: false,
  [REDUCER_STATE.SERVICE.FIELDS.SERVICES]: [],
  [REDUCER_STATE.SERVICE.FIELDS.SERVICE]: null,
  [REDUCER_STATE.SERVICE.FIELDS.ERROR]: null
}
const reducers = {}
const extraReducers = {
  //get all services
  [getServices.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.SERVICE.FIELDS.ERROR]: null,
    [REDUCER_STATE.SERVICE.FIELDS.IS_GETTING_SERVICES]: true,
    [REDUCER_STATE.SERVICE.FIELDS.SERVICES]: []
  }),
  [getServices.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.SERVICE.FIELDS.IS_GETTING_SERVICES]: false,
    [REDUCER_STATE.SERVICE.FIELDS.SERVICES]: action.payload
  }),
  [getServices.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.SERVICE.FIELDS.ERROR]: action.error,
    [REDUCER_STATE.SERVICE.FIELDS.IS_GETTING_SERVICES]: false
  }),
  //get one service
  [getServiceById.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.SERVICE.FIELDS.ERROR]: null,
    [REDUCER_STATE.SERVICE.FIELDS.IS_GETTING_SERVICE]: true,
    [REDUCER_STATE.SERVICE.FIELDS.SERVICE]: null
  }),
  [getServiceById.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.SERVICE.FIELDS.IS_GETTING_SERVICE]: false,
    [REDUCER_STATE.SERVICE.FIELDS.SERVICE]: action.payload.service
  }),
  [getServiceById.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.SERVICE.FIELDS.ERROR]: action.error,
    [REDUCER_STATE.SERVICE.FIELDS.IS_GETTING_SERVICE]: false
  }),
  //create service
  [createService.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.SERVICE.FIELDS.ERROR]: null,
    [REDUCER_STATE.SERVICE.FIELDS.IS_CREATING_SERVICE]: true,
    [REDUCER_STATE.SERVICE.FIELDS.SERVICE]: null
  }),
  [createService.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.SERVICE.FIELDS.IS_CREATING_SERVICE]: false,
    [REDUCER_STATE.SERVICE.FIELDS.SERVICE]: action.payload
  }),
  [createService.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.SERVICE.FIELDS.IS_CREATING_SERVICE]: false,
    [REDUCER_STATE.SERVICE.FIELDS.ERROR]: action.error
  }),
  //update service
  [updateService.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.SERVICE.FIELDS.ERROR]: null,
    [REDUCER_STATE.SERVICE.FIELDS.IS_UPDATING_SERVICE]: true,
    [REDUCER_STATE.SERVICE.FIELDS.SERVICE]: null
  }),
  [updateService.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.SERVICE.FIELDS.IS_UPDATING_SERVICE]: false,
    [REDUCER_STATE.SERVICE.FIELDS.SERVICE]: action.payload
  }),
  [updateService.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.SERVICE.FIELDS.IS_UPDATING_SERVICE]: false,
    [REDUCER_STATE.SERVICE.FIELDS.ERROR]: action.error
  })
}
const slice = createSlice({
  name: REDUCER_STATE.SERVICE.NAME,
  initialState,
  reducers,
  extraReducers
})

export default slice
