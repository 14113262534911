import localforage from 'localforage'
import { isNil } from 'ramda'
import { NAME, VERSION } from './constants'

/**
 * Local storage base client
 */
class Store {
  /**
   * Constructor
   */
  constructor({ storeName }) {
    if (isNil(storeName)) {
      throw new Error('Please supply a store name!')
    }
    this.store = localforage.createInstance({
      name: NAME,
      storeName,
      version: VERSION
    })
  }

  /**
   * Clear store
   * @return {Promise}
   */
  clear() {
    return this.store.clear()
  }

  /**
   * Get store item
   * @param {string} key
   * @return {Promise}
   */
  getItem(key) {
    return this.store.getItem(key)
  }

  /**
   * Remove store item
   * @param {string} key
   * @return {Promise}
   */
  removeItem(key) {
    return this.store.removeItem(key)
  }

  /**
   * Set store item
   * @param {string} key
   * @param {*} value
   * @return {Promise}
   */
  setItem(key, value) {
    return this.store.setItem(key, value)
  }
}

export default Store
