import { createAsyncThunk } from '@reduxjs/toolkit'
import { userApi } from '@/services/api'
import USER_ACTION_TYPES from '@/store/types/user'

const getUsers = createAsyncThunk(USER_ACTION_TYPES.GET_USERS, async () => {
  const data = await userApi.getUsers()
  return data.users
})

const getUserByUsername = createAsyncThunk(USER_ACTION_TYPES.GET_USER, async (payload) => {
  return await userApi.getUserByUsername(payload)
})

const updateUser = createAsyncThunk(USER_ACTION_TYPES.UPDATE_USER, async (payload) => {
  return await userApi.updateUser(payload)
})

const createUser = createAsyncThunk(USER_ACTION_TYPES.CREATE_USER, async (payload) => {
  return await userApi.createUser(payload)
})

const deleteUser = createAsyncThunk(USER_ACTION_TYPES.DELETE_USER, async (payload) => {
  return await userApi.deleteUser(payload)
})
export { getUsers, getUserByUsername, updateUser, createUser, deleteUser }
