import BaseApi from './base'
import notificationApi from '@/components/notification'
import { MSG } from '@/constants/messages'

/**
 * Unique Teacher Participant api class that wraps multiple Unique Teacher Participant api requests
 */

class UniqueTeacherParticipantApi extends BaseApi {
  // get request
  async getUniqueTeacherParticipants() {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.UNIQUE_TEACHER_PARTICIPANTS)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  // get by ID
  async getUniqueTeacherParticipantById({ uniqueTeacherParticipantId }) {
    const _self = this
    try {
      const res = await _self.client.get(
        _self.endpoints.UNIQUE_TEACHER_PARTICIPANT({ uniqueTeacherParticipantId })
      )
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  // update request
  async updateUniqueTeacherParticipant({ uniqueTeacherParticipantId, ...rest }) {
    const _self = this
    try {
      const res = await _self.client.put(
        _self.endpoints.UNIQUE_TEACHER_PARTICIPANT({ uniqueTeacherParticipantId }),
        rest
      )
      notificationApi.success(MSG.UNIQUE_TEACHER_PARTICIPANT_UPDATED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  // delete request
  async deleteUniqueTeacherParticipant({ uniqueTeacherParticipantId }) {
    const _self = this
    try {
      const res = await _self.client.delete(
        _self.endpoints.UNIQUE_TEACHER_PARTICIPANT({ uniqueTeacherParticipantId })
      )
      notificationApi.success(MSG.UNIQUE_TEACHER_PARTICIPANT_DELETED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  // post request
  async refreshUniqueTeacherParticipants() {
    const _self = this
    try {
      const res = await _self.client.post(_self.endpoints.REFRESH_UNIQUE_TEACHER_PARTICIPANTS)
      notificationApi.success(MSG.UNIQUE_TEACHER_PARTICIPANT_REFRESHED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }
}

export default UniqueTeacherParticipantApi
