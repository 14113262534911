import { createAsyncThunk } from '@reduxjs/toolkit'
import { familyObservApi } from '@/services/api'
import FAMILY_OBSERV_ACTION_TYPES from '@/store/types/familyObserv'

const getFamiliesObserv = createAsyncThunk(
  FAMILY_OBSERV_ACTION_TYPES.GET_FAMILIES_OBSERV,
  async () => {
    const data = await familyObservApi.getFamiliesObserv()
    return data.familyObservationals
  }
)

const getFamilyObservById = createAsyncThunk(
  FAMILY_OBSERV_ACTION_TYPES.GET_FAMILY_OBSERV,
  async (payload) => {
    const data = await familyObservApi.getFamilyObservById(payload)
    return data.familyObservational
  }
)

const updateFamilyObserv = createAsyncThunk(
  FAMILY_OBSERV_ACTION_TYPES.UPDATE_FAMILY_OBSERV,
  async (payload) => {
    return await familyObservApi.updateFamilyObserv(payload)
  }
)

const createFamilyObserv = createAsyncThunk(
  FAMILY_OBSERV_ACTION_TYPES.CREATE_FAMILY_OBSERV,
  async (payload) => {
    return await familyObservApi.createFamilyObserv(payload)
  }
)

const deleteFamilyObserv = createAsyncThunk(
  FAMILY_OBSERV_ACTION_TYPES.DELETE_FAMILY_OBSERV,
  async (payload) => {
    return await familyObservApi.deleteFamilyObserv(payload)
  }
)
export {
  getFamiliesObserv,
  getFamilyObservById,
  updateFamilyObserv,
  createFamilyObserv,
  deleteFamilyObserv
}
