import {
  createYear4Teacher,
  deleteYear4Teacher,
  getYear4TeacherById,
  getYear4Teachers,
  updateYear4Teacher
} from '@/store/actions/year4Teacher'
import { REDUCER_STATE } from '../../constants/store'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.IS_GETTING_TEACHERS]: false,
  [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.IS_CREATING]: false,
  [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.IS_GETTING_TEACHER]: false,
  [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.IS_UPDATING]: false,
  [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.IS_DELETING]: false,
  [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.TEACHERS]: [],
  [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.TEACHER]: null,
  [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.ERROR]: null
}
const reducers = {}
const extraReducers = {
  //get all Year4Teachers
  [getYear4Teachers.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.ERROR]: null,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.IS_GETTING_TEACHERS]: true,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.TEACHERS]: []
  }),
  [getYear4Teachers.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.IS_GETTING_TEACHERS]: false,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.TEACHERS]: action.payload
  }),
  [getYear4Teachers.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.ERROR]: action.error,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.IS_GETTING_TEACHERS]: false
  }),
  //get one Year4Teacher
  [getYear4TeacherById.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.ERROR]: null,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.IS_GETTING_TEACHER]: true,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.TEACHER]: null
  }),
  [getYear4TeacherById.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.IS_GETTING_TEACHER]: false,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.TEACHER]: action.payload
  }),
  [getYear4TeacherById.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.ERROR]: action.error,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.IS_GETTING_TEACHER]: false
  }),
  //create Year4Teacher
  [createYear4Teacher.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.ERROR]: null,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.IS_CREATING]: true,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.TEACHER]: null
  }),
  [createYear4Teacher.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.IS_CREATING]: false,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.TEACHER]: action.payload
  }),
  [createYear4Teacher.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.IS_CREATING]: false,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.ERROR]: action.error
  }),
  //update Year4Teacher
  [updateYear4Teacher.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.ERROR]: null,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.IS_UPDATING]: true,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.TEACHER]: null
  }),
  [updateYear4Teacher.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.IS_UPDATING]: false,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.TEACHER]: action.payload
  }),
  [updateYear4Teacher.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.IS_UPDATING]: false,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.ERROR]: action.error
  }),
  //delete Year4Teacher
  [deleteYear4Teacher.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.ERROR]: null,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.IS_DELETING]: true,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.TEACHER]: null
  }),
  [deleteYear4Teacher.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.IS_DELETING]: false,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.TEACHER]: action.payload
  }),
  [deleteYear4Teacher.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.IS_DELETING]: false,
    [REDUCER_STATE.YEAR_4_TEACHER.FIELDS.ERROR]: action.error
  })
}
const slice = createSlice({
  name: REDUCER_STATE.YEAR_4_TEACHER.NAME,
  initialState,
  reducers,
  extraReducers
})

export default slice
