import {
  createYear3Teacher,
  deleteYear3Teacher,
  getYear3TeacherById,
  getYear3Teachers,
  updateYear3Teacher
} from '@/store/actions/year3Teacher'
import { REDUCER_STATE } from '../../constants/store'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.IS_GETTING_TEACHERS]: false,
  [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.IS_CREATING]: false,
  [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.IS_GETTING_TEACHER]: false,
  [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.IS_UPDATING]: false,
  [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.IS_DELETING]: false,
  [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.TEACHERS]: [],
  [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.TEACHER]: null,
  [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.ERROR]: null
}
const reducers = {}
const extraReducers = {
  //get all Year3Teachers
  [getYear3Teachers.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.ERROR]: null,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.IS_GETTING_TEACHERS]: true,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.TEACHERS]: []
  }),
  [getYear3Teachers.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.IS_GETTING_TEACHERS]: false,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.TEACHERS]: action.payload
  }),
  [getYear3Teachers.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.ERROR]: action.error,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.IS_GETTING_TEACHERS]: false
  }),
  //get one Year3Teacher
  [getYear3TeacherById.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.ERROR]: null,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.IS_GETTING_TEACHER]: true,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.TEACHER]: null
  }),
  [getYear3TeacherById.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.IS_GETTING_TEACHER]: false,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.TEACHER]: action.payload
  }),
  [getYear3TeacherById.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.ERROR]: action.error,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.IS_GETTING_TEACHER]: false
  }),
  //create Year3Teacher
  [createYear3Teacher.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.ERROR]: null,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.IS_CREATING]: true,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.TEACHER]: null
  }),
  [createYear3Teacher.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.IS_CREATING]: false,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.TEACHER]: action.payload
  }),
  [createYear3Teacher.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.IS_CREATING]: false,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.ERROR]: action.error
  }),
  //update Year3Teacher
  [updateYear3Teacher.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.ERROR]: null,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.IS_UPDATING]: true,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.TEACHER]: null
  }),
  [updateYear3Teacher.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.IS_UPDATING]: false,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.TEACHER]: action.payload
  }),
  [updateYear3Teacher.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.IS_UPDATING]: false,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.ERROR]: action.error
  }),
  //delete Year3Teacher
  [deleteYear3Teacher.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.ERROR]: null,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.IS_DELETING]: true,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.TEACHER]: null
  }),
  [deleteYear3Teacher.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.IS_DELETING]: false,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.TEACHER]: action.payload
  }),
  [deleteYear3Teacher.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.IS_DELETING]: false,
    [REDUCER_STATE.YEAR_3_TEACHER.FIELDS.ERROR]: action.error
  })
}
const slice = createSlice({
  name: REDUCER_STATE.YEAR_3_TEACHER.year34TeachersOfService,
  initialState,
  reducers,
  extraReducers
})

export default slice
