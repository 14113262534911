import React, { lazy, Suspense } from 'react'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import PublicRoute from './publicRoute'
import PrivateRoute from './privateRoute'

import Loading from '@/components/loading'
import { ROOT_ROUTES } from '@/constants/routes'
import { REDUCER_STATE } from '@/constants/store'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const LoginView = lazy(() => import('@/views/login'))
const LogoutView = lazy(() => import('@/views/logout'))
const MenuLayoutView = lazy(() => import('@/views/menuLayout'))

/**
 * Root Routes
 */
const RootRoutes = (props) => {
  return (
    <HashRouter>
      <Suspense fallback={<Loading fullscreen={true} />}>
        <Switch>
          <PublicRoute path={ROOT_ROUTES.LOGIN_VIEW} authUser={props.authUser}>
            <LoginView />
          </PublicRoute>
          <Route path={ROOT_ROUTES.LOGOUT_VIEW} component={LogoutView} />
          <PrivateRoute path={ROOT_ROUTES.MENU_LAYOUT_VIEW}>
            <MenuLayoutView />
          </PrivateRoute>
          <Route path={ROOT_ROUTES.ALL}>
            <Redirect to={ROOT_ROUTES.MENU_LAYOUT_VIEW} />
          </Route>
        </Switch>
      </Suspense>
    </HashRouter>
  )
}

RootRoutes.propTypes = {
  authUser: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    authUser: state[REDUCER_STATE.AUTH.NAME][REDUCER_STATE.AUTH.FIELDS.DATA_AUTH_USER]
  }
}

export default connect(mapStateToProps)(RootRoutes)
