import BaseApi from './base'
import notificationApi from '@/components/notification'
import { MSG } from '@/constants/messages'

/**
 * Director api class that wraps multiple Director api requests
 */
class DirectorApi extends BaseApi {
  async getDirectors() {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.DIRECTORS)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async getDirectorById({ directorId }) {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.DIRECTOR({ directorId }))
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async createDirector(body) {
    const _self = this
    try {
      const res = await _self.client.post(_self.endpoints.DIRECTORS, body)
      notificationApi.success(MSG.DIRECTOR_CREATED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async updateDirector({ directorId, ...rest }) {
    const _self = this
    try {
      const res = await _self.client.put(_self.endpoints.DIRECTOR({ directorId }), rest)
      notificationApi.success(MSG.DIRECTOR_UPDATED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }
  async deleteDirector({ directorId }) {
    const _self = this
    try {
      const res = await _self.client.delete(_self.endpoints.DIRECTOR({ directorId }))
      notificationApi.success(MSG.DIRECTOR_DELETED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }
}

export default DirectorApi
