/**
 * Api error wrapper
 */
class ApiError extends Error {
  constructor(message, name, stack) {
    super(message)
    this.name = name
    this.stack = stack
  }
}

export default ApiError
