import BaseApi from './base'
import notificationApi from '@/components/notification'
import { MSG } from '@/constants/messages'

/**
 * user api class that wraps multiple user api requests
 */
class Year4TeacherApi extends BaseApi {
  async getYear4Teachers() {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.YEAR_4_TEACHERS)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async getYear4TeacherById({ teacherId }) {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.YEAR_4_TEACHER({ teacherId }))
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async createYear4Teacher(body) {
    const _self = this
    try {
      const res = await _self.client.post(_self.endpoints.YEAR_4_TEACHERS, body)
      notificationApi.success(MSG.YEAR_4_TEACHER_CREATED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async updateYear4Teacher({ teacherId, ...rest }) {
    const _self = this
    try {
      const res = await _self.client.put(_self.endpoints.YEAR_4_TEACHER({ teacherId }), rest)
      notificationApi.success(MSG.YEAR_4_TEACHER_UPDATED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async deleteYear4Teacher({ teacherId }) {
    const _self = this
    try {
      const res = await _self.client.delete(_self.endpoints.YEAR_4_TEACHER({ teacherId }))
      notificationApi.success(MSG.YEAR_4_TEACHER_DELETED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }
}

export default Year4TeacherApi
