import BaseApi from './base'

/**
 * survey api class that wraps multiple survey api requests
 */
class SurveyApi extends BaseApi {
  async getSurveys() {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.SURVEYS)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async getSurveyById({ surveyId }) {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.SURVEY({ surveyId }))
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async getCurrentContactList() {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.CURRENT_CONTACT_LIST)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async updateContacts() {
    const _self = this
    try {
      const res = await _self.client.post(_self.endpoints.UPDATE_CONTACTS)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }
}

export default SurveyApi
