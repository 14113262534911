import BaseApi from './base'
import notificationApi from '@/components/notification'
import { MSG } from '@/constants/messages'

/**
 * import api class that can initialise services
 */

class ExportApi extends BaseApi {
  async getAllExportArchives() {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.EXPORT_ARCHIVES)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }
  async getOneExportArchive({ archiveId }) {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.EXPORT_ARCHIVE({ archiveId }))
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }
  async downloadOneArchive({ archiveId }) {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.DOWNLOAD_ARCHIVE({ archiveId }), {
        responseType: 'blob'
      })
      return new Blob([res?.data], {
        type: res.headers['content-type']
      })
    } catch (err) {
      await _self.errorHandler(err)
    }
  }
  async exportReport(body) {
    const _self = this
    try {
      const res = await _self.client.post(_self.endpoints.EXPORT_REPORT, body, {
        responseType: 'blob'
      })
      notificationApi.success(MSG.EXPORT_REPORT_SUCCESS)
      return new Blob([res?.data], {
        type: res.headers['content-type']
      })
    } catch (err) {
      await _self.errorHandler(err)
    }
  }
}

export default ExportApi
