import {
  initializeServices,
  enrolDirectors,
  enrolYear3Teachers,
  enrolYear4Teachers,
  enrolFamiliesCross,
  enrolFamiliesObserv
} from '@/store/actions/import'
import { REDUCER_STATE } from '@/constants/store'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  [REDUCER_STATE.IMPORT.FIELDS.IS_INITIALIZING_SERVICES]: false,
  [REDUCER_STATE.IMPORT.FIELDS.IS_ENROLLING_DIRECTORS]: false,
  [REDUCER_STATE.IMPORT.FIELDS.IS_ENROLLING_YEAR_3_TEACHERS]: false,
  [REDUCER_STATE.IMPORT.FIELDS.IS_ENROLLING_YEAR_4_TEACHERS]: false,
  [REDUCER_STATE.IMPORT.FIELDS.IS_ENROLLING_FAMILIES_CROSS]: false,
  [REDUCER_STATE.IMPORT.FIELDS.ERROR]: null
}
const reducers = {}
const extraReducers = {
  //initialize services
  [initializeServices.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.IMPORT.FIELDS.ERROR]: null,
    [REDUCER_STATE.IMPORT.FIELDS.IS_INITIALIZING_SERVICES]: true
  }),
  [initializeServices.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.IMPORT.FIELDS.IS_INITIALIZING_SERVICES]: false,
    [REDUCER_STATE.IMPORT.FIELDS.SERVICE]: action.payload
  }),
  [initializeServices.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.IMPORT.FIELDS.IS_INITIALIZING_SERVICES]: false,
    [REDUCER_STATE.IMPORT.FIELDS.ERROR]: action.error
  }),
  //enroll directors
  [enrolDirectors.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.IMPORT.FIELDS.ERROR]: null,
    [REDUCER_STATE.IMPORT.FIELDS.IS_ENROLLING_DIRECTORS]: true
  }),
  [enrolDirectors.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.IMPORT.FIELDS.IS_ENROLLING_DIRECTORS]: false
  }),
  [enrolDirectors.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.IMPORT.FIELDS.IS_ENROLLING_DIRECTORS]: false,
    [REDUCER_STATE.IMPORT.FIELDS.ERROR]: action.error
  }),

  //enroll year 3 teachers
  [enrolYear3Teachers.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.IMPORT.FIELDS.ERROR]: null,
    [REDUCER_STATE.IMPORT.FIELDS.IS_ENROLLING_YEAR_3_TEACHERS]: true
  }),
  [enrolYear3Teachers.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.IMPORT.FIELDS.IS_ENROLLING_YEAR_3_TEACHERS]: false
  }),
  [enrolYear3Teachers.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.IMPORT.FIELDS.IS_ENROLLING_YEAR_3_TEACHERS]: false,
    [REDUCER_STATE.IMPORT.FIELDS.ERROR]: action.error
  }),

  //enroll year 4 teachers
  [enrolYear4Teachers.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.IMPORT.FIELDS.ERROR]: null,
    [REDUCER_STATE.IMPORT.FIELDS.IS_ENROLLING_YEAR_4_TEACHERS]: true
  }),
  [enrolYear4Teachers.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.IMPORT.FIELDS.IS_ENROLLING_YEAR_4_TEACHERS]: false
  }),
  [enrolYear4Teachers.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.IMPORT.FIELDS.IS_ENROLLING_YEAR_4_TEACHERS]: false,
    [REDUCER_STATE.IMPORT.FIELDS.ERROR]: action.error
  }),

  //enroll cross-sectional families
  [enrolFamiliesCross.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.IMPORT.FIELDS.ERROR]: null,
    [REDUCER_STATE.IMPORT.FIELDS.IS_ENROLLING_FAMILIES_CROSS]: true
  }),
  [enrolFamiliesCross.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.IMPORT.FIELDS.IS_ENROLLING_FAMILIES_CROSS]: false
  }),
  [enrolFamiliesCross.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.IMPORT.FIELDS.IS_ENROLLING_FAMILIES_CROSS]: false,
    [REDUCER_STATE.IMPORT.FIELDS.ERROR]: action.error
  }),

  //enroll cross-sectional families
  [enrolFamiliesObserv.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.IMPORT.FIELDS.ERROR]: null,
    [REDUCER_STATE.IMPORT.FIELDS.IS_ENROLLING_FAMILIES_OBSERV]: true
  }),
  [enrolFamiliesObserv.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.IMPORT.FIELDS.IS_ENROLLING_FAMILIES_OBSERV]: false
  }),
  [enrolFamiliesObserv.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.IMPORT.FIELDS.IS_ENROLLING_FAMILIES_OBSERV]: false,
    [REDUCER_STATE.IMPORT.FIELDS.ERROR]: action.error
  })
}
const slice = createSlice({
  name: REDUCER_STATE.IMPORT.NAME,
  initialState,
  reducers,
  extraReducers
})

export default slice
