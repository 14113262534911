import AuthApi from '@/services/api/modules/auth'
import UserApi from '@/services/api/modules/user'
import SettingApi from '@/services/api/modules/setting'
import SurveyApi from '@/services/api/modules/survey'
import ServiceApi from '@/services/api/modules/service'
import ImportApi from '@/services/api/modules/import'
import Year3TeacherApi from '@/services/api/modules/year3Teacher'
import Year4TeacherApi from '@/services/api/modules/year4Teacher'
import ExportApi from '@/services/api/modules/export'
import DirectorApi from '@/services/api/modules/director'
import FamilyCrossApi from '@/services/api/modules/familyCross'
import FamilyObservApi from '@/services/api/modules/familyObserv'
import UniqueTeacherParticipantApi from '@/services/api/modules/uniqueTeacherParticipant'

const authApi = new AuthApi()
const userApi = new UserApi()
const settingApi = new SettingApi()
const surveyApi = new SurveyApi()
const serviceApi = new ServiceApi()
const importApi = new ImportApi()
const year3TeacherApi = new Year3TeacherApi()
const year4TeacherApi = new Year4TeacherApi()
const exportApi = new ExportApi()
const directorApi = new DirectorApi()
const familyCrossApi = new FamilyCrossApi()
const familyObservApi = new FamilyObservApi()
const uniqueTeacherParticipantApi = new UniqueTeacherParticipantApi()

export {
  authApi,
  userApi,
  settingApi,
  surveyApi,
  serviceApi,
  importApi,
  year3TeacherApi,
  year4TeacherApi,
  exportApi,
  directorApi,
  familyCrossApi,
  familyObservApi,
  uniqueTeacherParticipantApi
}
