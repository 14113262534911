import {
  getDirectors,
  getDirectorById,
  updateDirector,
  createDirector,
  deleteDirector
} from '@/store/actions/director'
import { REDUCER_STATE } from '../../constants/store'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  [REDUCER_STATE.DIRECTOR.FIELDS.IS_GETTING_DIRECTORS]: false,
  [REDUCER_STATE.DIRECTOR.FIELDS.IS_CREATING_DIRECTOR]: false,
  [REDUCER_STATE.DIRECTOR.FIELDS.IS_GETTING_DIRECTOR]: false,
  [REDUCER_STATE.DIRECTOR.FIELDS.IS_UPDATING_DIRECTOR]: false,
  [REDUCER_STATE.DIRECTOR.FIELDS.IS_DELETING_DIRECTOR]: false,
  [REDUCER_STATE.DIRECTOR.FIELDS.DIRECTORS]: [],
  [REDUCER_STATE.DIRECTOR.FIELDS.DIRECTOR]: null,
  [REDUCER_STATE.DIRECTOR.FIELDS.ERROR]: null
}
const reducers = {}
const extraReducers = {
  //get all Directors
  [getDirectors.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.DIRECTOR.FIELDS.ERROR]: null,
    [REDUCER_STATE.DIRECTOR.FIELDS.IS_GETTING_DIRECTORS]: true,
    [REDUCER_STATE.DIRECTOR.FIELDS.DIRECTORS]: []
  }),
  [getDirectors.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.DIRECTOR.FIELDS.IS_GETTING_DIRECTORS]: false,
    [REDUCER_STATE.DIRECTOR.FIELDS.DIRECTORS]: action.payload
  }),
  [getDirectors.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.DIRECTOR.FIELDS.ERROR]: action.error,
    [REDUCER_STATE.DIRECTOR.FIELDS.IS_GETTING_DIRECTORS]: false
  }),
  //get one Director
  [getDirectorById.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.DIRECTOR.FIELDS.ERROR]: null,
    [REDUCER_STATE.DIRECTOR.FIELDS.IS_GETTING_DIRECTOR]: true,
    [REDUCER_STATE.DIRECTOR.FIELDS.DIRECTOR]: null
  }),
  [getDirectorById.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.DIRECTOR.FIELDS.IS_GETTING_DIRECTOR]: false,
    [REDUCER_STATE.DIRECTOR.FIELDS.DIRECTOR]: action.payload
  }),
  [getDirectorById.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.DIRECTOR.FIELDS.ERROR]: action.error,
    [REDUCER_STATE.DIRECTOR.FIELDS.IS_GETTING_DIRECTOR]: false
  }),
  //create Director
  [createDirector.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.DIRECTOR.FIELDS.ERROR]: null,
    [REDUCER_STATE.DIRECTOR.FIELDS.IS_CREATING_DIRECTOR]: true,
    [REDUCER_STATE.DIRECTOR.FIELDS.DIRECTOR]: null
  }),
  [createDirector.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.DIRECTOR.FIELDS.IS_CREATING_DIRECTOR]: false,
    [REDUCER_STATE.DIRECTOR.FIELDS.DIRECTOR]: action.payload
  }),
  [createDirector.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.DIRECTOR.FIELDS.IS_CREATING_DIRECTOR]: false,
    [REDUCER_STATE.DIRECTOR.FIELDS.ERROR]: action.error
  }),
  //update Director
  [updateDirector.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.DIRECTOR.FIELDS.ERROR]: null,
    [REDUCER_STATE.DIRECTOR.FIELDS.IS_UPDATING_DIRECTOR]: true,
    [REDUCER_STATE.DIRECTOR.FIELDS.DIRECTOR]: null
  }),
  [updateDirector.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.DIRECTOR.FIELDS.IS_UPDATING_DIRECTOR]: false,
    [REDUCER_STATE.DIRECTOR.FIELDS.DIRECTOR]: action.payload
  }),
  [updateDirector.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.DIRECTOR.FIELDS.IS_UPDATING_DIRECTOR]: false,
    [REDUCER_STATE.DIRECTOR.FIELDS.ERROR]: action.error
  }),
  //delete Director
  [deleteDirector.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.DIRECTOR.FIELDS.ERROR]: null,
    [REDUCER_STATE.DIRECTOR.FIELDS.IS_DELETING_DIRECTOR]: true,
    [REDUCER_STATE.DIRECTOR.FIELDS.DIRECTOR]: null
  }),
  [deleteDirector.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.DIRECTOR.FIELDS.IS_DELETING_DIRECTOR]: false,
    [REDUCER_STATE.DIRECTOR.FIELDS.DIRECTOR]: action.payload
  }),
  [deleteDirector.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.DIRECTOR.FIELDS.IS_DELETING_DIRECTOR]: false,
    [REDUCER_STATE.DIRECTOR.FIELDS.ERROR]: action.error
  })
}
const slice = createSlice({
  name: REDUCER_STATE.DIRECTOR.NAME,
  initialState,
  reducers,
  extraReducers
})

export default slice
