import BaseApi from './base'
import notificationApi from '@/components/notification'
import { MSG } from '@/constants/messages'

/**
 * Family Cross api class that wraps multiple Family Cross api requests
 */
class FamilyObservApi extends BaseApi {
  async getFamiliesObserv() {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.FAMILIES_OBSERV)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async getFamilyObservById({ familyObservId }) {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.FAMILY_OBSERV({ familyObservId }))
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async createFamilyObserv(body) {
    const _self = this
    try {
      const res = await _self.client.post(_self.endpoints.FAMILIES_OBSERV, body)
      notificationApi.success(MSG.FAMILY_OBSERV_CREATED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async updateFamilyObserv({ familyObservId, ...rest }) {
    const _self = this
    try {
      const res = await _self.client.put(_self.endpoints.FAMILY_OBSERV({ familyObservId }), rest)
      notificationApi.success(MSG.FAMILY_OBSERV_UPDATED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }
  async deleteFamilyObserv({ familyObservId }) {
    const _self = this
    try {
      const res = await _self.client.delete(_self.endpoints.FAMILY_OBSERV({ familyObservId }))
      notificationApi.success(MSG.FAMILY_OBSERV_DELETED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }
}

export default FamilyObservApi
