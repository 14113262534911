import { combineReducers } from '@reduxjs/toolkit'
import { createHashHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import authSlice from '@/store/reducers/auth'
import userSlice from '@/store/reducers/user'
import settingSlice from '@/store/reducers/setting'
import surveySlice from '@/store/reducers/survey'
import serviceSlice from '@/store/reducers/service'
import year3TeacherSlice from '@/store/reducers/year3Teacher'
import year4TeacherSlice from '@/store/reducers/year4Teacher'
import importSlice from '@/store/reducers/import'
import exportSlice from '@/store/reducers/export'
import directorSlice from '@/store/reducers/director'
import familyCrossSlice from '@/store/reducers/familyCross'
import familyObservSlice from '@/store/reducers/familyObserv'
import uniqueTeacherParticipantSlice from '@/store/reducers/uniqueTeacherParticipant'
import { REDUCER_STATE } from '@/constants/store'

export const history = createHashHistory()
const createRootReducer = (history) =>
  combineReducers({
    [REDUCER_STATE.ROUTER.NAME]: connectRouter(history),
    [REDUCER_STATE.USER.NAME]: userSlice.reducer,
    [REDUCER_STATE.AUTH.NAME]: authSlice.reducer,
    [REDUCER_STATE.SETTING.NAME]: settingSlice.reducer,
    [REDUCER_STATE.SURVEY.NAME]: surveySlice.reducer,
    [REDUCER_STATE.SERVICE.NAME]: serviceSlice.reducer,
    [REDUCER_STATE.YEAR_3_TEACHER.year34TeachersOfService]: year3TeacherSlice.reducer,
    [REDUCER_STATE.YEAR_4_TEACHER.NAME]: year4TeacherSlice.reducer,
    [REDUCER_STATE.IMPORT.NAME]: importSlice.reducer,
    [REDUCER_STATE.EXPORT.NAME]: exportSlice.reducer,
    [REDUCER_STATE.DIRECTOR.NAME]: directorSlice.reducer,
    [REDUCER_STATE.FAMILY_CROSS.NAME]: familyCrossSlice.reducer,
    [REDUCER_STATE.FAMILY_OBSERV.NAME]: familyObservSlice.reducer,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.NAME]: uniqueTeacherParticipantSlice.reducer
  })

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

function configureStore(preloadedState) {
  return createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
  )
}

export const store = configureStore()
