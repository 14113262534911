import {
  getAllArchives,
  exportReport,
  getOneArchive,
  downloadOneArchive
} from '@/store/actions/export'
import { REDUCER_STATE } from '../../constants/store'
import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  [REDUCER_STATE.EXPORT.FIELDS.ARCHIVE]: null,
  [REDUCER_STATE.EXPORT.FIELDS.ARCHIVES]: [],
  [REDUCER_STATE.EXPORT.FIELDS.ARCHIVE_URL]: null,
  [REDUCER_STATE.EXPORT.FIELDS.REPORT_URL]: null,
  [REDUCER_STATE.EXPORT.FIELDS.IS_GETTING_ARCHIVE]: false,
  [REDUCER_STATE.EXPORT.FIELDS.IS_GETTING_ARCHIVES]: false,
  [REDUCER_STATE.EXPORT.FIELDS.IS_EXPORTING_REPORT]: false,
  [REDUCER_STATE.EXPORT.FIELDS.EXPORT_ERROR]: null,
  [REDUCER_STATE.EXPORT.FIELDS.ARCHIVE_ERROR]: null
}
const reducers = {}
const extraReducers = {
  //get all archives
  [getAllArchives.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.EXPORT.FIELDS.ARCHIVE_ERROR]: null,
    [REDUCER_STATE.EXPORT.FIELDS.IS_GETTING_ARCHIVES]: true
  }),
  [getAllArchives.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.EXPORT.FIELDS.IS_GETTING_ARCHIVES]: false,
    [REDUCER_STATE.EXPORT.FIELDS.ARCHIVES]: action.payload
  }),
  [getAllArchives.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.EXPORT.FIELDS.IS_GETTING_ARCHIVES]: false,
    [REDUCER_STATE.EXPORT.FIELDS.ARCHIVE_ERROR]: action.error
  }),
  //get one archive
  [getOneArchive.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.EXPORT.FIELDS.ARCHIVE_ERROR]: null,
    [REDUCER_STATE.EXPORT.FIELDS.IS_GETTING_ARCHIVE]: true
  }),
  [getOneArchive.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.EXPORT.FIELDS.IS_GETTING_ARCHIVE]: false,
    [REDUCER_STATE.EXPORT.FIELDS.ARCHIVE]: action.payload
  }),
  [getOneArchive.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.EXPORT.FIELDS.IS_GETTING_ARCHIVE]: false,
    [REDUCER_STATE.EXPORT.FIELDS.ARCHIVE_ERROR]: action.error
  }),

  //Download one archive
  [downloadOneArchive.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.EXPORT.FIELDS.ARCHIVE_ERROR]: null,
    [REDUCER_STATE.EXPORT.FIELDS.IS_DOWNLOADING_ARCHIVE]: true
  }),
  [downloadOneArchive.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.EXPORT.FIELDS.IS_DOWNLOADING_ARCHIVE]: false,
    [REDUCER_STATE.EXPORT.FIELDS.ARCHIVE_URL]: action.payload
  }),
  [downloadOneArchive.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.EXPORT.FIELDS.IS_DOWNLOADING_ARCHIVE]: false,
    [REDUCER_STATE.EXPORT.FIELDS.ARCHIVE_ERROR]: action.error
  }),

  //Export a report
  [exportReport.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.EXPORT.FIELDS.EXPORT_ERROR]: null,
    [REDUCER_STATE.EXPORT.FIELDS.IS_EXPORTING_REPORT]: true
  }),
  [exportReport.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.EXPORT.FIELDS.IS_EXPORTING_REPORT]: false,
    [REDUCER_STATE.EXPORT.FIELDS.REPORT_URL]: action.payload
  }),
  [exportReport.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.EXPORT.FIELDS.IS_EXPORTING_REPORT]: false,
    [REDUCER_STATE.EXPORT.FIELDS.EXPORT_ERROR]: action.error
  })
}
const slice = createSlice({
  name: REDUCER_STATE.EXPORT.NAME,
  initialState,
  reducers,
  extraReducers
})

export default slice
