/**
 * Message values definition. It is used to show user messages e.g. Warning, info etc..
 */
export const MSG = {
  USER_CREATED: 'User created.',
  USER_UPDATED: 'User updated.',
  USER_DELETED: 'User deleted.',
  SERVICE_CREATED: 'Service created.',
  SERVICE_UPDATED: 'Service updated.',
  SERVICE_DELETED: 'Service deleted.',
  YEAR_3_TEACHER_CREATED: 'Year 3 teacher created.',
  YEAR_3_TEACHER_UPDATED: 'Year 3 teacher updated.',
  YEAR_3_TEACHER_DELETED: 'Year 3 teacher deleted.',
  YEAR_4_TEACHER_CREATED: 'Year 4 teacher created.',
  YEAR_4_TEACHER_UPDATED: 'Year 4 teacher updated.',
  YEAR_4_TEACHER_DELETED: 'Year 4 teacher deleted.',
  CHANGE_SAVED: 'Change saved',
  DATA_SAVED: 'Data saved',
  FILE_UPLOADED: 'File uploaded',
  PASSWORD_UPDATED: 'Password has been updated',
  PASSWORD_RESET_SUCCESS: 'Your password has been reset, please re-login.',
  EXPORT_REPORT_SUCCESS: 'Report exported.',
  DIRECTOR_CREATED: 'Director created.',
  DIRECTOR_UPDATED: 'Director updated.',
  DIRECTOR_DELETED: 'Director deleted.',
  YEAR_3_TEACHERS_ENROLLED: 'Year 3 teachers enrolled.',
  YEAR_3_TEACHERS_PARTIALLY_ENROLLED: 'Year 3 teachers email address conflicts.',
  YEAR_4_TEACHERS_ENROLLED: 'Year 4 teachers enrolled.',
  YEAR_4_TEACHERS_PARTIALLY_ENROLLED: 'Year 4 teachers email address conflicts.',
  DIRECTORS_ENROLLED: 'Directors enrolled.',
  DIRECTORS_PARTIALLY_ENROLLED: 'Directors email address conflicts.',
  FAMILY_CROSS_CREATED: 'Cross-Sectional Family created',
  FAMILY_CROSS_UPDATED: 'Cross-Sectional Family updated',
  FAMILY_CROSS_DELETED: 'Cross-Sectional Family deleted',
  FAMILIES_CROSS_ENROLLED: 'Cross-Sectional Family enrolled',
  FAMILIES_CROSS_PARTIALLY_ENROLLED: 'Cross-Sectional Families email address conflicts.',
  FAMILY_OBSERV_CREATED: 'Observational Family created',
  FAMILY_OBSERV_UPDATED: 'Observational Family updated',
  FAMILY_OBSERV_DELETED: 'Observational Family deleted',
  FAMILIES_OBSERV_ENROLLED: 'Observational Family enrolled',
  FAMILIES_OBSERV_PARTIALLY_ENROLLED: 'Observational Families email address conflicts.',
  UNIQUE_TEACHER_PARTICIPANT_UPDATED: 'Unique Teacher Participant updated.',
  UNIQUE_TEACHER_PARTICIPANT_DELETED: 'Unique Teacher Participant deleted.',
  UNIQUE_TEACHER_PARTICIPANT_REFRESHED: 'Unique Teacher Participant refreshed.'
}

export const ERR_MSG = {
  EXISTING_ENTITY: 'Entity exists',
  DATA_NOT_AVAILABLE: 'Data is not available',
  DATA_NOT_SUPPORTED: 'Data structure incorrect',
  INVALID_OPERATION: 'Invalid operation',
  UNAUTHENTICATED: 'Action required user login',
  UNAUTHORIZED: 'Permission denied',
  UNKNOWN_ERROR: 'An unknown error occurred, please try later',
  USERNAME_PASSWORD_MISMATCH: 'Username or password is incorrect',
  SERVER_INERNAL_ERROR: 'Server internal error, please again try later',
  INVALID_ROLLOUT_YEAR: 'Invalid rollout year at row #',
  EMPTY_SERVICE_NAME: 'Empty service name around at row #',
  INVALID_SERVICE_TYPE: 'Invalid service type at row # ',
  EMPTY_PROVIDER_NAME: 'Empty provider name around at row #',
  INVALID_PROVIDER_TYPE: 'Invalid provider type at row # ',
  ERROR_READING_FILE: `Error occurs when trying to read the spreadsheet. Make sure there is sheet with name "Numeric".`,
  ERROR_GET_SERVICE: 'Error occurs when trying to get Services from the server.',
  ERROR_GET_Y3_TEACHER: 'Error occurs when trying to get Year 3 Teachers from the server.',
  ERROR_GET_Y4_TEACHER: 'Error occurs when trying to get Year 4 Teachers from the server.'
}

export const LOADING_MSG = {
  INITIALIZING_SERVICES: 'Initializing services...'
}
