import { REDUCER_STATE } from '../../constants/store'
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteUniqueTeacherParticipant,
  getUniqueTeacherParticipantById,
  getUniqueTeacherParticipants,
  refreshUniqueTeacherParticipants,
  updateUniqueTeacherParticipant
} from '@/store/actions/uniqueTeacherParticipant'

const initialState = {
  [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.IS_GETTING_UNIQUE_TEACHER_PARTICIPANTS]: false,
  [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.IS_GETTING_UNIQUE_TEACHER_PARTICIPANT]: false,
  [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.IS_UPDATING_UNIQUE_TEACHER_PARTICIPANT]: false,
  [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.IS_DELETING_UNIQUE_TEACHER_PARTICIPANT]: false,
  [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.UNIQUE_TEACHER_PARTICIPANTS]: [],
  [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.UNIQUE_TEACHER_PARTICIPANT]: null,
  [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.ERROR]: null
}
const reducers = {}
const extraReducers = {
  //get all Unique Teacher Participants
  [getUniqueTeacherParticipants.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.ERROR]: null,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.IS_GETTING_UNIQUE_TEACHER_PARTICIPANTS]: true,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.UNIQUE_TEACHER_PARTICIPANTS]: []
  }),
  [getUniqueTeacherParticipants.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.IS_GETTING_UNIQUE_TEACHER_PARTICIPANTS]: false,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.UNIQUE_TEACHER_PARTICIPANTS]: action.payload
  }),
  [getUniqueTeacherParticipants.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.ERROR]: action.error,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.IS_GETTING_UNIQUE_TEACHER_PARTICIPANTS]: false
  }),
  //get one Unique Teacher Participant
  [getUniqueTeacherParticipantById.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.ERROR]: null,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.IS_GETTING_UNIQUE_TEACHER_PARTICIPANT]: true,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.DIRECTOR]: null
  }),
  [getUniqueTeacherParticipantById.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.IS_GETTING_UNIQUE_TEACHER_PARTICIPANT]: false,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.UNIQUE_TEACHER_PARTICIPANT]: action.payload
  }),
  [getUniqueTeacherParticipantById.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.ERROR]: action.error,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.IS_GETTING_UNIQUE_TEACHER_PARTICIPANT]: false
  }),
  //update Unique Teacher participant
  [updateUniqueTeacherParticipant.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.ERROR]: null,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.IS_UPDATING_UNIQUE_TEACHER_PARTICIPANT]: true,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.UNIQUE_TEACHER_PARTICIPANT]: null
  }),
  [updateUniqueTeacherParticipant.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.IS_UPDATING_UNIQUE_TEACHER_PARTICIPANT]: false,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.UNIQUE_TEACHER_PARTICIPANT]: action.payload
  }),
  [updateUniqueTeacherParticipant.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.IS_UPDATING_UNIQUE_TEACHER_PARTICIPANT]: false,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.ERROR]: action.error
  }),
  //delete Unique Teacher Participant
  [deleteUniqueTeacherParticipant.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.ERROR]: null,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.IS_DELETING_UNIQUE_TEACHER_PARTICIPANT]: true,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.UNIQUE_TEACHER_PARTICIPANT]: null
  }),
  [deleteUniqueTeacherParticipant.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.IS_DELETING_UNIQUE_TEACHER_PARTICIPANT]: false,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.UNIQUE_TEACHER_PARTICIPANT]: action.payload
  }),
  [deleteUniqueTeacherParticipant.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.IS_DELETING_UNIQUE_TEACHER_PARTICIPANT]: false,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.ERROR]: action.error
  }),
  //refresh Unique Teacher Participants
  [refreshUniqueTeacherParticipants.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.ERROR]: null,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS
      .IS_REFRESHING_UNIQUE_TEACHER_PARTICIPANTS]: true,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.UNIQUE_TEACHER_PARTICIPANT]: null
  }),
  [refreshUniqueTeacherParticipants.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS
      .IS_REFRESHING_UNIQUE_TEACHER_PARTICIPANTS]: false,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.UNIQUE_TEACHER_PARTICIPANT]: action.payload
  }),
  [refreshUniqueTeacherParticipants.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS
      .IS_REFRESHING_UNIQUE_TEACHER_PARTICIPANTS]: false,
    [REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.FIELDS.ERROR]: action.error
  })
}
const slice = createSlice({
  name: REDUCER_STATE.UNIQUE_TEACHER_PARTICIPANT.NAME,
  initialState,
  reducers,
  extraReducers
})

export default slice
