import { createAsyncThunk } from '@reduxjs/toolkit'
import { directorApi } from '@/services/api'
import DIRECTOR_ACTION_TYPES from '@/store/types/director'

const getDirectors = createAsyncThunk(DIRECTOR_ACTION_TYPES.GET_DIRECTORS, async () => {
  const data = await directorApi.getDirectors()
  return data.directors
})

const getDirectorById = createAsyncThunk(DIRECTOR_ACTION_TYPES.GET_DIRECTOR, async (payload) => {
  const data = await directorApi.getDirectorById(payload)
  return data.director
})

const updateDirector = createAsyncThunk(DIRECTOR_ACTION_TYPES.UPDATE_DIRECTOR, async (payload) => {
  return await directorApi.updateDirector(payload)
})

const createDirector = createAsyncThunk(DIRECTOR_ACTION_TYPES.CREATE_DIRECTOR, async (payload) => {
  return await directorApi.createDirector(payload)
})

const deleteDirector = createAsyncThunk(DIRECTOR_ACTION_TYPES.DELETE_DIRECTOR, async (payload) => {
  return await directorApi.deleteDirector(payload)
})
export { getDirectors, getDirectorById, updateDirector, createDirector, deleteDirector }
