const ttl = (strings, ...keys) => {
  return function (collection) {
    let result = [strings[0]]
    collection = collection || {}
    keys.forEach(function (key, i) {
      result.push(collection[key], strings[i + 1])
    })
    return result.join('')
  }
}

export { ttl }
