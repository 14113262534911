import client from '@/services/api/client'
import endpoints from '@/services/api/endpoints'
import { errorHandler, extractResponse } from '@/services/api/utils'

/**
 * JSEND compatible base api class
 */
class BaseApi {
  constructor() {
    this.client = client
    this.endpoints = endpoints
    this.errorHandler = errorHandler
    this.postProcessor = extractResponse
  }
}

export default BaseApi
