import { getUser, login, logout, refreshToken } from '@/store/actions/auth'
import { SET_IS_EXPIRED } from '@/store/types/auth'

import { REDUCER_STATE } from '@/constants/store'
import { createSlice } from '@reduxjs/toolkit'

const {
  AUTH: { FIELDS }
} = REDUCER_STATE

const initialState = {
  // data
  [FIELDS.DATA_AUTH_USER]: null,

  // pending
  [FIELDS.IS_LOGGING_IN]: false,
  [FIELDS.IS_GETTING_USER]: false,
  [FIELDS.IS_GETTING_REFRESH_TOKEN]: false,

  // error
  [FIELDS.ERROR_LOGIN]: null,
  [FIELDS.ERROR_GET_USER]: null,
  [FIELDS.ERROR_REFRESH_TOKEN]: null,

  [FIELDS.IS_EXPIRED]: false
}

const reducers = {}

const extraReducers = {
  [getUser.pending]: (state) => ({
    ...state,
    [FIELDS.IS_GETTING_USER]: true,
    [FIELDS.ERROR_GET_USER]: null
  }),
  [getUser.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_USER]: false,
    [FIELDS.DATA_AUTH_USER]: action.payload
  }),
  [getUser.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_USER]: false,
    [FIELDS.DATA_AUTH_USER]: null,
    [FIELDS.ERROR_GET_USER]: action.error
  }),

  [login.pending]: (state) => ({
    ...state,
    [FIELDS.IS_LOGGING_IN]: true,
    [FIELDS.DATA_AUTH_USER]: null,
    [FIELDS.ERROR_LOGIN]: null
  }),
  [login.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_LOGGING_IN]: false,
    [FIELDS.DATA_AUTH_USER]: action.payload,
    [FIELDS.ERROR_REFRESH_TOKEN]: null,
    [FIELDS.IS_EXPIRED]: false
  }),
  [login.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_LOGGING_IN]: false,
    [FIELDS.ERROR_LOGIN]: action.error
  }),

  [refreshToken.pending]: (state) => ({
    ...state,
    [FIELDS.IS_GETTING_REFRESH_TOKEN]: true,
    [FIELDS.ERROR_REFRESH_TOKEN]: null
  }),
  [refreshToken.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_REFRESH_TOKEN]: false,
    [FIELDS.DATA_AUTH_USER]: action.payload,
    [FIELDS.IS_EXPIRED]: false
  }),
  [refreshToken.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_REFRESH_TOKEN]: false,
    [FIELDS.DATA_AUTH_USER]: null,
    [FIELDS.ERROR_REFRESH_TOKEN]: action.error
  }),

  [logout.fulfilled]: (state) => ({
    ...state,
    [FIELDS.DATA_AUTH_USER]: null
  }),

  [SET_IS_EXPIRED]: (state, action) => ({
    ...state,
    [FIELDS.IS_EXPIRED]: action.isExpired
  })
}
const slice = createSlice({
  name: REDUCER_STATE.AUTH.NAME,
  initialState,
  reducers,
  extraReducers
})

export default slice
