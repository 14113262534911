import React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { store, history } from '@/store'
import RootRoutes from '@/routes/root'
import { GlobalWrapper } from '@/styles'
import '@/styles/index.less'
import { Helmet } from 'react-helmet'

const App = () => {
  return (
    <Provider store={store}>
      <Helmet>
        <title>EDGE</title>
      </Helmet>
      <ConnectedRouter history={history}>
        <GlobalWrapper>
          <RootRoutes />
        </GlobalWrapper>
      </ConnectedRouter>
    </Provider>
  )
}

export default App
