import { createAsyncThunk } from '@reduxjs/toolkit'
import { year3TeacherApi } from '@/services/api'
import YEAR_3_TEACHER_ACTION_TYPES from '@/store/types/year3Teacher'

const getYear3Teachers = createAsyncThunk(
  YEAR_3_TEACHER_ACTION_TYPES.GET_YEAR_3_TEACHERS,
  async () => {
    const data = await year3TeacherApi.getYear3Teachers()
    return data.year3Teachers
  }
)

const getYear3TeacherById = createAsyncThunk(
  YEAR_3_TEACHER_ACTION_TYPES.GET_YEAR_3_TEACHER,
  async (payload) => {
    const data = await year3TeacherApi.getYear3TeacherById(payload)
    return data.year3Teacher
  }
)

const updateYear3Teacher = createAsyncThunk(
  YEAR_3_TEACHER_ACTION_TYPES.UPDATE_YEAR_3_TEACHER,
  async (payload) => {
    return await year3TeacherApi.updateYear3Teacher(payload)
  }
)

const createYear3Teacher = createAsyncThunk(
  YEAR_3_TEACHER_ACTION_TYPES.CREATE_YEAR_3_TEACHER,
  async (payload) => {
    return await year3TeacherApi.createYear3Teacher(payload)
  }
)

const deleteYear3Teacher = createAsyncThunk(
  YEAR_3_TEACHER_ACTION_TYPES.DELETE_YEAR_3_TEACHER,
  async (payload) => {
    return await year3TeacherApi.deleteYear3Teacher(payload)
  }
)
export {
  getYear3Teachers,
  getYear3TeacherById,
  updateYear3Teacher,
  createYear3Teacher,
  deleteYear3Teacher
}
