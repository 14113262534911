import { createAsyncThunk } from '@reduxjs/toolkit'
import { serviceApi } from '@/services/api'
import SERVICE_ACTION_TYPES from '@/store/types/service'

const getServices = createAsyncThunk(SERVICE_ACTION_TYPES.GET_SERVICES, async () => {
  const data = await serviceApi.getServices()
  return data.services
})

const getServiceById = createAsyncThunk(SERVICE_ACTION_TYPES.GET_SERVICE, async (payload) => {
  return await serviceApi.getServiceById(payload)
})

const updateService = createAsyncThunk(SERVICE_ACTION_TYPES.UPDATE_SERVICE, async (payload) => {
  return await serviceApi.updateService(payload)
})

const createService = createAsyncThunk(SERVICE_ACTION_TYPES.CREATE_SERVICE, async (payload) => {
  return await serviceApi.createService(payload)
})

export { getServices, getServiceById, updateService, createService }
