const ROOT_ROUTES = {
  // public routes
  LOGIN_VIEW: '/login',

  // protected routes
  MENU_LAYOUT_VIEW: '/',
  SERVICES: '/services',
  USERS: '/users',
  SETTINGS: '/settings',
  LOGOUT_VIEW: '/logout',
  DIRECTORS: '/directors',
  YEAR_3_TEACHERS: '/year-3-teachers',
  YEAR_4_TEACHERS: '/year-4-teachers',
  UNIQUE_TEACHER_PARTICIPANTS: '/unique-teacher-participants',
  FAMILY_CROSS: '/family-cross-sectional',
  FAMILY_OBSERV: '/family-observational',
  EXPORTS: '/exports',
  ALL: '*'
}

const FAMILY_CROSS_ROUTES = {
  INDEX: '/family-cross-sectional',
  NEW: '/family-cross-sectional/new',
  EXIST: '/family-cross-sectional/:familyCrossId',
  OF_SERVICE: '/family-cross-sectional/of-service/:serviceId'
}

const FAMILY_OBSERV_ROUTES = {
  INDEX: '/family-observational',
  NEW: '/family-observational/new',
  EXIST: '/family-observational/:familyObservId',
  OF_SERVICE: '/family-observational/of-service/:serviceId'
}

const USER_ROUTES = {
  INDEX: '/users',
  NEW: '/users/new',
  EXIST: '/users/:username'
}

const SERVICE_ROUTES = {
  INDEX: '/services',
  NEW: '/services/new',
  EXIST: '/services/:serviceId'
}

const YEAR_3_TEACHER_ROUTES = {
  INDEX: '/year-3-teachers',
  NEW: '/year-3-teachers/new',
  EXIST: '/year-3-teachers/:teacherId',
  OF_SERVICE: '/year-3-teachers/of-service/:serviceId'
}

const YEAR_4_TEACHER_ROUTES = {
  INDEX: '/year-4-teachers',
  NEW: '/year-4-teachers/new',
  EXIST: '/year-4-teachers/:teacherId',
  OF_SERVICE: '/year-4-teachers/of-service/:serviceId'
}

const DIRECTOR_ROUTES = {
  INDEX: '/directors',
  NEW: '/directors/new',
  EXIST: '/directors/:directorId',
  OF_SERVICE: '/directors/of-service/:serviceId'
}

const UNIQUE_TEACHER_PARTICIPANT_ROUTES = {
  INDEX: '/unique-teacher-participants',
  NEW: '/unique-teacher-participants/new',
  EXIST: '/unique-teacher-participants/:unique-teacher-participantID',
  OF_SERVICE: 'unique-teacher-participants/of-service/;unique-teacher-participantID'
}

export {
  ROOT_ROUTES,
  USER_ROUTES,
  SERVICE_ROUTES,
  FAMILY_CROSS_ROUTES,
  FAMILY_OBSERV_ROUTES,
  YEAR_3_TEACHER_ROUTES,
  YEAR_4_TEACHER_ROUTES,
  DIRECTOR_ROUTES,
  UNIQUE_TEACHER_PARTICIPANT_ROUTES
}
