import ApiError from '@/services/api/error'
import notificationApi from '@/components/notification'
import { ERR_MSG } from '@/constants/messages'

/**
 * JSEND compatible API response extractor
 */
const extractResponse = (res) => {
  if (res.data.data) return res.data.data
  else return res.data
}

/**
 * JSEND compatible API error handler
 * @param {Object} error
 * @param {Boolean} displayError
 */
const errorHandler = async (error, displayError = true) => {
  let errorDataObject
  if (
    error.request.responseType === 'blob' &&
    error.response.data instanceof Blob &&
    error.response.data.type &&
    error.response.data.type.toLowerCase().indexOf('json') !== -1
  ) {
    // in the situation when the response type is blob, convert to json
    errorDataObject = JSON.parse(await error.response.data.text())
  } else {
    errorDataObject = error.response?.data
  }

  const statusCode = error.response?.status

  if (errorDataObject?.status) {
    let err
    // the api response is JSEND compatible
    if (errorDataObject.status === 'fail') {
      // the api response is JSEND Fail
      const failTitle = errorDataObject.data.title || 'Client Side Error'
      const failMessage = errorDataObject.data.message || 'Unknown'
      const failStackTrace = errorDataObject.data.stackTrace || null
      if (displayError) {
        err = new Error(failMessage)
        err.status = statusCode
        displayErrorNotification(err)
      }
      throw new ApiError(failMessage, failTitle, failStackTrace)
    } else if (errorDataObject.status === 'error') {
      // the api response is JSEND Error
      const errorMessage = errorDataObject.message || 'Unknown'
      if (displayError) {
        err = new Error(errorMessage)
        err.status = statusCode
        displayErrorNotification(err)
      }
      throw new ApiError(errorMessage, 'Server side error', null)
    }
  } else {
    if (displayError) notificationApi.error(error.message, ERR_MSG.UNKNOWN_ERROR)
    throw error
  }
}

/**
 * Popup notification for API error
 * @param {object} error - error
 */
const displayErrorNotification = (error) => {
  if (error.status === 400) {
    notificationApi.error(error.message, ERR_MSG.INVALID_OPERATION)
  } else if (error.status === 401) {
    notificationApi.error(error.message, ERR_MSG.UNAUTHENTICATED)
  } else if (error.status === 403) {
    // Forbidden error
    notificationApi.error(error.message, ERR_MSG.UNAUTHORIZED)
  } else if (error.status === 404) {
    notificationApi.error(error.message, ERR_MSG.DATA_NOT_AVAILABLE)
  } else if (error.status === 409) {
    notificationApi.error(error.message, ERR_MSG.EXISTING_ENTITY)
  } else if (error.status === 422) {
    notificationApi.error(error.message, ERR_MSG.DATA_NOT_SUPPORTED)
  } else if (error.status === 500) {
    notificationApi.error(error.message, ERR_MSG.SERVER_INERNAL_ERROR)
  } else {
    notificationApi.error(error.message, ERR_MSG.UNKNOWN_ERROR)
  }
}

export { errorHandler, extractResponse }
