import { getSettings, getSettingByType, updateSetting } from '@/store/actions/setting'
import { REDUCER_STATE } from '../../constants/store'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  [REDUCER_STATE.SETTING.FIELDS.IS_GETTING_SETTINGS]: false,
  [REDUCER_STATE.SETTING.FIELDS.IS_GETTING_SETTING]: false,
  [REDUCER_STATE.SETTING.FIELDS.IS_UPDATING_SETTING]: false,
  [REDUCER_STATE.SETTING.FIELDS.SETTINGS]: [],
  [REDUCER_STATE.SETTING.FIELDS.SETTING]: null,
  [REDUCER_STATE.SETTING.FIELDS.ERROR]: null
}
const reducers = {}
const extraReducers = {
  //get all settings
  [getSettings.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.SETTING.FIELDS.ERROR]: null,
    [REDUCER_STATE.SETTING.FIELDS.IS_GETTING_SETTINGS]: true,
    [REDUCER_STATE.SETTING.FIELDS.SETTINGS]: []
  }),
  [getSettings.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.SETTING.FIELDS.IS_GETTING_SETTINGS]: false,
    [REDUCER_STATE.SETTING.FIELDS.SETTINGS]: action.payload
  }),
  [getSettings.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.SETTING.FIELDS.ERROR]: action.error,
    [REDUCER_STATE.SETTING.FIELDS.IS_GETTING_SETTINGS]: false
  }),
  //get one SETTING
  [getSettingByType.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.SETTING.FIELDS.ERROR]: null,
    [REDUCER_STATE.SETTING.FIELDS.IS_GETTING_SETTING]: true,
    [REDUCER_STATE.SETTING.FIELDS.SETTING]: null
  }),
  [getSettingByType.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.SETTING.FIELDS.IS_GETTING_SETTING]: false,
    [REDUCER_STATE.SETTING.FIELDS.SETTING]: action.payload.SETTING
  }),
  [getSettingByType.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.SETTING.FIELDS.ERROR]: action.error,
    [REDUCER_STATE.SETTING.FIELDS.IS_GETTING_SETTING]: false
  }),
  //update SETTING
  [updateSetting.pending]: (state) => ({
    ...state,
    [REDUCER_STATE.SETTING.FIELDS.ERROR]: null,
    [REDUCER_STATE.SETTING.FIELDS.IS_UPDATING_SETTING]: true,
    [REDUCER_STATE.SETTING.FIELDS.SETTING]: null
  }),
  [updateSetting.fulfilled]: (state, action) => ({
    ...state,
    [REDUCER_STATE.SETTING.FIELDS.IS_UPDATING_SETTING]: false,
    [REDUCER_STATE.SETTING.FIELDS.SETTING]: action.payload
  }),
  [updateSetting.rejected]: (state, action) => ({
    ...state,
    [REDUCER_STATE.SETTING.FIELDS.IS_UPDATING_SETTING]: false,
    [REDUCER_STATE.SETTING.FIELDS.ERROR]: action.error
  })
}
const slice = createSlice({
  name: REDUCER_STATE.SETTING.NAME,
  initialState,
  reducers,
  extraReducers
})

export default slice
