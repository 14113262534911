import BaseApi from './base'
import notificationApi from '@/components/notification'
import { MSG } from '@/constants/messages'

/**
 * user api class that wraps multiple user api requests
 */
class UserApi extends BaseApi {
  async getUsers() {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.USERS)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async getUserByUsername({ username }) {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.USER({ username }))
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async createUser({ username, roles, password }) {
    const _self = this
    try {
      const body = { username, roles, password }
      const res = await _self.client.post(_self.endpoints.USERS, body)
      notificationApi.success(MSG.USER_CREATED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async updateUser({ username, newUsername, roles, password }) {
    const _self = this
    try {
      const body = {
        username: newUsername,
        roles,
        password
      }
      const res = await _self.client.put(_self.endpoints.USER({ username }), body)
      notificationApi.success(MSG.USER_UPDATED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }
  async deleteUser({ username }) {
    const _self = this
    try {
      const res = await _self.client.delete(_self.endpoints.USER({ username }))
      notificationApi.success(MSG.USER_DELETED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }
}

export default UserApi
