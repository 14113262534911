import { createAsyncThunk } from '@reduxjs/toolkit'
import { year4TeacherApi } from '@/services/api'
import YEAR_4_TEACHER_ACTION_TYPES from '@/store/types/year4Teacher'

const getYear4Teachers = createAsyncThunk(
  YEAR_4_TEACHER_ACTION_TYPES.GET_YEAR_4_TEACHERS,
  async () => {
    const data = await year4TeacherApi.getYear4Teachers()
    return data.year4Teachers
  }
)

const getYear4TeacherById = createAsyncThunk(
  YEAR_4_TEACHER_ACTION_TYPES.GET_YEAR_4_TEACHER,
  async (payload) => {
    const data = await year4TeacherApi.getYear4TeacherById(payload)
    return data.year4Teacher
  }
)

const updateYear4Teacher = createAsyncThunk(
  YEAR_4_TEACHER_ACTION_TYPES.UPDATE_YEAR_4_TEACHER,
  async (payload) => {
    return await year4TeacherApi.updateYear4Teacher(payload)
  }
)

const createYear4Teacher = createAsyncThunk(
  YEAR_4_TEACHER_ACTION_TYPES.CREATE_YEAR_4_TEACHER,
  async (payload) => {
    return await year4TeacherApi.createYear4Teacher(payload)
  }
)

const deleteYear4Teacher = createAsyncThunk(
  YEAR_4_TEACHER_ACTION_TYPES.DELETE_YEAR_4_TEACHER,
  async (payload) => {
    return await year4TeacherApi.deleteYear4Teacher(payload)
  }
)
export {
  getYear4Teachers,
  getYear4TeacherById,
  updateYear4Teacher,
  createYear4Teacher,
  deleteYear4Teacher
}
