import React, { Fragment, useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import Loading from '@/components/loading'
import { ROOT_ROUTES } from '@/constants/routes'
import { connect } from 'react-redux'
import { REDUCER_STATE } from '@/constants/store'
import { refreshToken } from '@/store/actions/auth'

/**
 * Only authenticated user can access private routes
 */
const PrivateRoute = ({ authUser, refreshToken, errorRefreshToken, children, ...rest }) => {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (authUser) {
      // user has been authenticated
      setReady(true)
    } else {
      if (errorRefreshToken) {
        // user has tried to re-login, but fail
        // will redirect to login path
        setReady(true)
      } else {
        // user hasn't tried to re-login, perform re-login
        setReady(false)
        refreshToken()
      }
    }
  }, [authUser, errorRefreshToken, refreshToken])

  return (
    <Fragment>
      {ready ? (
        <Route
          {...rest}
          render={({ location }) =>
            authUser ? (
              children
            ) : (
              <Redirect to={{ pathname: ROOT_ROUTES.LOGIN_VIEW, state: { from: location } }} />
            )
          }
        />
      ) : (
        <Loading />
      )}
    </Fragment>
  )
}
PrivateRoute.propTypes = {
  authUser: PropTypes.object,
  errorRefreshToken: PropTypes.object,
  refreshToken: PropTypes.func,
  children: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    authUser: state[REDUCER_STATE.AUTH.NAME][REDUCER_STATE.AUTH.FIELDS.DATA_AUTH_USER],
    errorRefreshToken: state[REDUCER_STATE.AUTH.NAME][REDUCER_STATE.AUTH.FIELDS.ERROR_REFRESH_TOKEN]
  }
}

const mapDispatchToProps = { refreshToken }

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
