import { createAsyncThunk } from '@reduxjs/toolkit'
import UNIQUE_TEACHER_PARTICIPANT_ACTION_TYPE from '@/store/types/uniqueTeacherParticipant'
import { uniqueTeacherParticipantApi } from '@/services/api'

const getUniqueTeacherParticipants = createAsyncThunk(
  UNIQUE_TEACHER_PARTICIPANT_ACTION_TYPE.GET_UNIQUE_TEACHER_PARTICIPANTS,
  async () => {
    const data = await uniqueTeacherParticipantApi.getUniqueTeacherParticipants()
    return data.uniqueTeacherParticipants
  }
)

const getUniqueTeacherParticipantById = createAsyncThunk(
  UNIQUE_TEACHER_PARTICIPANT_ACTION_TYPE.GET_UNIQUE_TEACHER_PARTICIPANT,
  async (payload) => {
    const data = await uniqueTeacherParticipantApi.getUniqueTeacherParticipantById(payload)
    return data.uniqueTeacherParticipant
  }
)

const updateUniqueTeacherParticipant = createAsyncThunk(
  UNIQUE_TEACHER_PARTICIPANT_ACTION_TYPE.UPDATE_UNIQUE_TEACHER_PARTICIPANT,
  async (payload) => {
    return await uniqueTeacherParticipantApi.updateUniqueTeacherParticipant(payload)
  }
)

const deleteUniqueTeacherParticipant = createAsyncThunk(
  UNIQUE_TEACHER_PARTICIPANT_ACTION_TYPE.DELETE_UNIQUE_TEACHER_PARTICIPANT,
  async (payload) => {
    return await uniqueTeacherParticipantApi.deleteUniqueTeacherParticipant(payload)
  }
)

const refreshUniqueTeacherParticipants = createAsyncThunk(
  UNIQUE_TEACHER_PARTICIPANT_ACTION_TYPE.REFRESH_UNIQUE_TEACHER_PARTICIPANTS,
  async (payload) => {
    return await uniqueTeacherParticipantApi.refreshUniqueTeacherParticipants(payload)
  }
)
export {
  getUniqueTeacherParticipants,
  getUniqueTeacherParticipantById,
  updateUniqueTeacherParticipant,
  deleteUniqueTeacherParticipant,
  refreshUniqueTeacherParticipants
}
