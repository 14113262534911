import BaseApi from './base'
import notificationApi from '@/components/notification'
import { MSG } from '@/constants/messages'

/**
 * user api class that wraps multiple user api requests
 */
class Year3TeacherApi extends BaseApi {
  async getYear3Teachers() {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.YEAR_3_TEACHERS)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async getYear3TeacherById({ teacherId }) {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.YEAR_3_TEACHER({ teacherId }))
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async createYear3Teacher(body) {
    const _self = this
    try {
      const res = await _self.client.post(_self.endpoints.YEAR_3_TEACHERS, body)
      notificationApi.success(MSG.YEAR_3_TEACHER_CREATED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async updateYear3Teacher({ teacherId, ...rest }) {
    const _self = this
    try {
      const res = await _self.client.put(_self.endpoints.YEAR_3_TEACHER({ teacherId }), rest)
      notificationApi.success(MSG.YEAR_3_TEACHER_UPDATED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }

  async deleteYear3Teacher({ teacherId }) {
    const _self = this
    try {
      const res = await _self.client.delete(_self.endpoints.YEAR_3_TEACHER({ teacherId }))
      notificationApi.success(MSG.YEAR_3_TEACHER_DELETED)
      return _self.postProcessor(res)
    } catch (err) {
      await _self.errorHandler(err)
    }
  }
}

export default Year3TeacherApi
